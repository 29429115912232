.btn {
  font-family: $headings-font-family;
  font-weight: 500;
  .icon-wrap {
    margin-right: 5px;
  }
}

.card-body {
  .btn {
    padding: 0.25rem 1rem;
  }
}

.btn-icon-only {
  .icon-wrap {
    margin-right: 0;
  }
}
.btn-outline-primary {
  border-width: 2px;
}

.btn-lg {
  padding: 0.5rem 1rem;
}
@each $color, $value in $theme-colors {
  .btn-transparent-#{$color} {
    @include button-variant(rgba($value, 0.8), rgba($value, 0.8));
  }
}
.btn-primary,
.btn-primary:disabled {
  color: $white;
  transition: 0.3s;
  &:hover {
    color: $white;
    background-color: $primary;
    transform: scale(110%);
    border: 1px solid transparent;
    background-color: #057da0;
  }
  &:active {
    color: $white;
    background-color: $primary;
    transform: scale(100%);
    background-color: #057da0;
    border: 1px solid transparent;
  }
}

.btn-primary.w-100 {
  &:hover {
    color: $white;
    background-color: $primary;
    transform: scale(100%);
    background-color: #057da0;
  }
}

.btn-wide {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.card-primary {
  .btn-white {
    color: $primary;
  }
}

.btn-xs,
.btn-group-xs > .btn {
  padding: 0.2rem 0.25rem;
  font-size: 0.75rem;
  border-radius: 0.2rem;
}

.btn-add {
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
}
.btn-icon-end {
  position: relative;
  .icon-wrap {
    position: absolute;
    right: 0rem;
  }
}

.sticky-button {
  z-index: 100;
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-box-shadow: 0px 0px 16px 16px rgba(255, 255, 255, 0.9);
  -moz-box-shadow: 0px 0px 16px 16px rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 16px 16px rgba(255, 255, 255, 0.9);
}
