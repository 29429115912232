.footer{
    display: none !important;
    a{
        color:#222529;
    }
    border-top:2px solid $white;
    font-size:0.75rem;
}
.inline-footer{
    font-size:0.75rem;

}

.inline-footer{
    font-size:0.75rem;
}
@include media-breakpoint-down(md) {

    .inline-footer{
        .col-md-2, .text-end, .text-left{
            text-align:center !important;
        }
    }
}