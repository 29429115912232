.dndPlaceholder {

    min-height: 45px;
    background-color: $light;
    border-radius: $border-radius;
    opacity:.5;
    margin-left:30px;
  }
  .dndDraggingSource{
      display:none !important;
  }


.goal-content {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: row;
    padding: 0.5rem 0;
  
    .goal-checkbox {
      min-width: 1.875rem;
      width: 1.875rem;
      height: 1.875rem;
      background-color: $white;
      border-radius: 0.625rem;
      display: inline-block;
      text-align: center;
      line-height: 1.875rem;
      margin-right: 0.625rem;
      color: #efefef;
    }
  }
  
  .goal-review-date{
    font-size:0.625rem;
    color:#3C5A64;
  }
  .flex-goal-status{
    padding:0.5rem 0.5rem 0.5rem 0rem;
    font-size:1.25rem;
  }

@include media-breakpoint-up(lg) {
  .goals-callto {
    display: none;
  }
}