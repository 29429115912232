.login {
  h2 {
    text-align: center;
  }
  .logo {
    display: block;
    margin: 30px auto;
  }
  .form-group {
    margin: 10px 0;
  }
  .input-group-text {
    height: 100%;
  }
  .card{
    border:none;
  }
}
.info-box{
    border-radius: 0 $border-radius $border-radius 0;
}

@media (min-width: 768px) {
  .gradient-form {
    height: 100vh !important;
  }
}
@media (max-width: 768px) {
    .info-box{
        border-radius: 0;
    }
}


.programme-logo-strap {
  font-size: 0.875rem;
  font-weight: normal;
  margin: 1rem 0 0.5rem 0;
  text-align: center;
}
.programme-logos {
  width: 100%;
  display: flex;
  justify-content: center;
  gap:2rem;
  img{
    max-width: 80px !important;
  }
}