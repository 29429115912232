.skipnav {
  position: absolute;
  display: block;
  text-align: left;
  margin: 0;
  padding: 0;
}
.skipnav a:focus,
.skipnav a:active {
  z-index: 99;
  top: 0;
  left: 0;
  border: solid $yellow 4px;
  color: $white;
  background: $primary !important;
}
.skipnav a {
  width: 26em;
  display: block;
  color: $primary;
  background: $white !important;
  text-decoration: none;
  padding: 5px;
  position: absolute;
  left: -1000em;
  top: 0;
  font-weight: bold;
}
