@charset "UTF-8";
// FA
@import "../node_modules/@fortawesome/fontawesome-svg-core/styles.css";

// bootstrap
@import "node_modules/bootstrap/scss/functions";
@import "styles/bootstrap/bootstrap-variables";

@import "node_modules/bootstrap/scss/bootstrap";


// swal
@import '../node_modules/sweetalert2/src/sweetalert2.scss';
@import '../node_modules/swiper/swiper.scss';
$sidebar-width: 340px;

$symptom0:#7AE571;
$symptom1:#BEEF8D;
$symptom2:#FFD800;
$symptom3:#FFB100;
$symptom4:#F26E24;;
$symptom5:#DE3535;

$sputum1: #e3e6d0;
$sputum2: #e0e6ae;
$sputum3: #c3cf60;
$sputum4: #a8b83b;
$sputum5: #8f9a1d;

$intensity-l:#FFD800;
$intensity-m:#FFB100;
$intensity-v:#DE3535;

// UI
@import "styles/_home";
@import "styles/_accessibility";
@import "styles/_general";
@import "styles/_buttons";
@import "styles/forms";
@import "styles/_navbar";
@import "styles/_footer-nav";
@import "styles/_sidebar";
@import "styles/_footer";
@import "styles/_animations";
@import "styles/aside-menu";
@import "styles/add-to-homescreen";
@import "styles/older-browsers";
@import "styles/cookie-consent";
@import "styles/loading-spinner";
@import "styles/tracker-list";
@import "styles/header-bar";
@import "styles/media-queries";
@import "styles/helpers";
@import "styles/_task-list";
@import "styles/_login";
@import "styles/_page-header";
@import "styles/_progress";
@import "styles/_tabs";
@import "styles/_typography";
@import "styles/_week-bar";
@import "styles/_difficulty";
@import "styles/_date-picker";
@import "styles/_status-calendar";
@import "styles/_achievements";
@import "styles/_achievement-notification";
@import "styles/_tables";
@import "styles/_cards";
@import "styles/_chat";
@import "styles/_speech-bubble";
@import "styles/_forum-list";
@import "styles/_forum-posts";
@import "styles/_breadcrumbs";
@import "styles/_goals";
@import "styles/_pick-list";
@import "styles/_todo";
@import "styles/_eq5d";
@import "styles/_quizzes";
@import "styles/_resources";
@import "styles/_symptom-thermometer";
@import "styles/_workouts";
@import "styles/_badges";
@import "styles/_strength";
@import "styles/_dashboard-mastheads";

// dark mode for OS' that support it
// @import "styles/_dark-mode";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";


@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
