.circle-intensity{
    display:flex;
    width:1.25rem;
    height:1.25rem;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-family: $headings-font-family;
    font-size: 0.625rem;
    text-transform: uppercase;
    font-weight: 700;
}
.circle-intensity-l{
   background-color:  $intensity-l;
   color:$dark;
   font-weight: 700;
}
.circle-intensity-m{
   background-color:  $intensity-m;
   color:$dark;
   font-weight: 700;
}
.circle-intensity-v{
   background-color:  $intensity-v;
   color:$white;
   font-weight: 700;
}
.circle-intensity-p{
   background-color:  $secondary;
   color:$white;
   font-weight: 700;
}

.circle-intensity-lg {
   display:flex;
    width:1.5rem;
    height:1.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-family: $headings-font-family;
    font-size: 0.75rem;
}

.stats-section {
   .small {
      font-size: 0.75rem;
      line-height: 1rem;
      display: inline-block;
   }
}

.status-badge {
   .row {
      gap: 1rem;
   }
}


@include media-breakpoint-down(sm) {
.stats-section {
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 1rem;
  margin-bottom: -1rem;
}
}

@include media-breakpoint-up(sm) {
   .stats-section {
     margin: 0;
     padding: 1rem;
     border-radius: 0.5rem;
   }
}