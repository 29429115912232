.table-workouts {
  width: 100%;
  font-size: 0.75rem;

  thead {
    tr {
      th {
        font-size: 0.625rem;
        text-transform: uppercase;
        font-weight: 700;
        text-align: center;
        //padding: .5rem .5rem 0.125rem .5rem
        padding-bottom: 0.125rem;
      }
    }
  }
  tbody {
    tr {
      position:relative;
      td {
        text-align: center;
        padding: 0.75rem 1rem;
      }

      td:first-child {
        text-align: left;
        font-weight: 700;
        border-radius: $border-radius 0 0 $border-radius;
      }
      td:last-child{
        padding: 0rem 1rem 0 0rem;
        border-radius: 0 $border-radius  $border-radius 0;
        text-align: right;
        
        .icon-wrap{
            font-size: 1.5rem;
        }
        
      }

      a {
        &:hover {
          color: $primary!important;
        }
      }

      .td-workout-date {
        padding: 0.75rem 0rem 0.75rem 1rem;
        width: 4.75rem;
      }

      .td-workout-type {
        padding: 0.75rem 0.5rem 0.75rem 0.5rem;
        min-width:9.25rem;
      }

      .td-workout-time {
        padding: 0.75rem 0rem 0.75rem 0rem;
        width: 4rem;
      }

      .td-workout-intensity {
        padding: 0.75rem 0rem;
        width: 1.25rem;
      }

      .td-workout-link {
        padding: 0rem!important;
        
        a {
          display: block;
          
          height: 2.75rem;
          line-height: 2.75rem;
          text-align: right;
          padding-right: 1rem;
          color: $secondary;
        }
      }

      .td-strength-date {
        padding: 0.75rem 0.5rem 0.75rem 1rem;
        width: 4.75rem;
      }

      .td-strength-exercises {
        padding: 0.75rem 0.5rem 0.75rem 0.5rem;
        .strength-icons-day {
          max-width: 200px;
          text-align: center;
          margin: 0 auto 0 auto;
        }
        
      }

      .td-strength-reps {
        padding: 0.75rem 0.5rem 0.75rem 0.5rem;
        
      }

      .td-strength-check {
        padding: 0.75rem 1rem 0.75rem 0.5rem!important;
        width: 1.5rem
      }

      

      
    }
    tr:nth-child(odd) {
      background-color: $light;
    }

    tr.disabled {
      td {
        opacity: 0.5;
      }
    }
  }
}

.table-workouts-flex {
  width: 100%;
  font-size: 0.75rem;

  thead {
    tr {
      th {
        font-family: $headings-font-family;
        font-size: 0.625rem;
        text-transform: uppercase;
        font-weight: 700;
        text-align: center;
        padding: .5rem .5rem 0.125rem .5rem
      }
    }
  }
  tbody {
    tr {

      td {
        display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;
  gap: 0.5rem;
  padding-left: 1rem;
  border-radius: $border-radius;
  overflow: hidden;
  position: relative;
      }

      td.strength-workout-table {
        gap: 0.25rem;
        .flex-workout-date {
            padding-right: 1rem!important;
            width: 4.75rem!important;;
            max-width: 4.75rem!important;;
            min-width: 4.75rem!important;;
        }
      }
    }

    tr:nth-child(odd) td {
      background-color: $light;
      border-radius: $border-radius;
    }

    tr.disabled {
      td {
        opacity: 0.5;
      }
    }
  }
  .flex-workout-date {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
    width: 3.75rem;
    max-width: 3.75rem;
    min-width: 3.75rem;
    padding: 0.75rem 0rem 0.75rem 0rem;
    font-weight: 700;
  }
  
  .flex-workout-type {
    display: block;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
    padding: 0.75rem 0rem 0.75rem 0rem;
  }
  
  .flex-workout-intensity {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
    width: 1.5rem;
    max-width: 1.5rem;
    min-width: 1.5rem;
    padding: 0.75rem 0rem 0.75rem 0rem;
  }
  
  .flex-workout-time {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
    width: 3rem;
    max-width: 3rem;
    min-width: 3rem;
    padding: 0.75rem 0rem 0.75rem 0rem;
  }
  
  .flex-workout-link {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
    padding: 0.75rem 1rem 0.75rem 0rem;
    color:$secondary;

    &:hover {
      color:$primary;
    }
  }

  .flex-strength-entry {
    display: block;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
    padding: 0.75rem 0rem 0.75rem 0rem;
  }
}

.all-weeks-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: normal;
  align-content: normal;
}

.week-title, .week-metrics {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;

  .metric-data {
    font-size:0.625rem;
  font-weight: 400;
  color:$secondary;
  display: block;
  text-align: right;
  }
}