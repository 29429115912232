.header-bar{
    background-color: $dark;
    color: $white;
    text-align:center;
    padding:10px;
    min-height:50px;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    h2{
        color: $white;
        font-size:1.125rem;
        text-transform: none;
        font-weight:300;
        line-height:1;
        margin:0;
        padding:0 0 5px 0;
    }
    h3{
        color: $white;
        font-size:0.625rem;
        text-transform: none;
        font-weight:300;
        line-height:1;
        margin:0;
        padding:0;
    }
    .back-link{
       position: absolute;
       left:2px;
        color:$white;
        font-size:1.125rem;
        display: inline;
        padding: 0.3rem 0.8rem;
        border-radius: $border-radius;
        &:hover {
          background-color: rgb(255,255,255,0.1);
        }
        &:active {
          background-color: rgb(255,255,255,0.2);
        }
    }
}

  // media queries
  @include media-breakpoint-up(md) {
    
    .header-bar {
      border-radius: 0 0 $border-radius $border-radius;
    }
  }