.add-to-homescreen {
  /* background:$primary;
    color:$white;
    position: fixed;
    width: 100vw;
    height:100vh;
    top:0;
    left:0;
    z-index: 9999;
    background-repeat: no-repeat;
    background-size: 90vw;
    background-position: center;
*/

  position: fixed;
  width: 100dvw;
  height: 100dvh;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: scroll;
  line-height: 1rem;

  .add-to-homescreen-wrap {
    width: 300px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    text-align: center;
    h1 {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
  }

  .masthead {
  }
  hr {
    border-color: $white;
  }

  .add-to-homescreen-benefits {
    font-size: 0.75rem;
    color: $secondary;
    padding-bottom: 0.5rem;
    ul {
      list-style: none;
      padding: 0rem;
      li {
        position: relative;
        margin-bottom: 1rem;
        padding-left: 1.5rem;
        strong {
          display: block;
        }
        .icon-wrap {
          font-size: 0.875rem;
          position: absolute;
          top: 0rem;
          left: 0;
        }
      }
    }
  }
  /*
    .instructions{
        .instructions-content{
            padding:1rem 2rem;
            .share-icon{
                position: relative;
                top:-5px;
            }
            .subtext{
                font-size:0.75rem;
            }
        }

        ol {
            text-align:left;
            margin: 1rem 0;
            padding-left: 0.1rem;
            list-style-type: none;
          }
        ol > li {
            margin-bottom: 1rem;
            margin-left: 2.25rem;
            position: relative;
            counter-increment: customlistcounter;
            p:last-child {
              margin-bottom: 3rem;
            }
          }
          ol > li:before {
          content: counter(customlistcounter) " ";
          font-weight: bold;
          float: left;
          width: 24px;
          height: 24px;
          line-height: 25px;
          border-radius: 50%;
          font-weight: bold;
          color: $dark;
          text-align: center;
          background-color: $white;
          margin-left: -1.5rem;
          left: -0.75rem;
          position: absolute;
          }
    }*/

  .card-instructions {
    overflow: hidden;
    
    .instructions-content {
      padding: 0.5rem 1rem 0.5rem 1rem;
      h5 {
        text-align: center;
        margin-bottom: 0;
        padding-bottom: 0;
      }
      .card-sub-title {
        font-size: 0.625rem;
        font-weight: normal;
        text-align: center;
      }
      font-size: 0.75rem;
      text-align: left;
      ol {
        padding: 0 1rem;
        margin-bottom: 0rem;
        li {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-height: 768px) and (orientation: landscape) {
  .add-to-homescreen {
    .add-to-homescreen-wrap {
      width: 300px;
      position: relative;
      top: 0%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      text-align: center;
      margin:50px 0;
    }
  }
}

/*
.background-container {
    background-position: top center;
    height: 100%;
    background-repeat: no-repeat;
}

.add-to-homescreen.iphone .background-container{
    background-image: url('/assets/img/iphone-app-launch.jpg');
    background-size: 85vw;
}
.add-to-homescreen.ipad .background-container {
    background-image: url('/assets/img/ipad-app-launch-02.jpg');
}

.add-to-homescreen.android .background-container {
    background-image: url('/assets/img/android-app-launch.jpg');
    background-size: 88vw;
}


.add-to-homescreen.ipad .masthead {
    padding:2rem 4rem 0.5rem 4rem;
    font-size:2.5rem;
    line-height: 3rem;
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {

    .add-to-homescreen.ipad .background-container {
        background-size: 110%;
        background-position: top right 2rem;
    }

}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
    .add-to-homescreen.ipad .background-container {
        background-size: 150%;
        background-position: top right 2rem;
    }
}

@media only screen 
and (min-device-width : 1024px) 
and (max-device-width : 1366px) 
and (orientation : landscape) {

    .add-to-homescreen.ipad .background-container {
        background-size: 110%;
        background-position: top right 2rem;
    }

}

@media only screen 
and (min-device-width : 1024px) 
and (max-device-width : 1366px) 
and (orientation : portrait) {
    .add-to-homescreen.ipad .background-container {
        background-size: 180%;
        background-position: top right 2rem;
    }
}*/
