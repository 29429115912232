.week-bar {

    border-radius:5px;
    border-radius: 2rem;

  .entry-days {
    display: flex;
    justify-content: center;
    align-items: center;

    .entry-day:first-child {
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
    }

    .entry-day:last-child{
        margin-right:0px;
        border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
    }

    .entry-day {
      text-align: center;
      flex: 1;
      color:$white;
      margin-right: 2px;
      background-color: rgba($white, 0.25);

      .icon-wrap-dot{
        font-size:	0.4375rem;
        position: relative;
        top:-4px;
      }
      .entry-day-label {
        font-size: 0.625rem;
        font-weight: 700;
        color:$white;
        margin-top: 0.125rem;
        margin-bottom: -0.4rem;
      }

      .entry-day-data {
        border-radius: 0.5rem;
        height: 1.9rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: $white;
        color: $primary;

        .entry-day-data-value {
          font-size: 1.0625rem;
          font-weight: 700;
          line-height: 1rem;
        }

        .entry-day-data-unit {
          font-size: 0.6875rem;
          line-height: 0.6875rem;
        }
      }


      .entry-day-data.na {
        background-color: rgba($white, 0.5);
        color: $primary;
      }
    }
    .entry-day.complete{
      background-color: $success;
      color: $white;
      margin-right: 2px;
      .entry-day-label {
        color: $dark;
      }
    }
  }
}
