.note{
    font-size:0.625rem;
}
.all-data-header{
    font-size:0.875rem;
    margin:0;
    font-weight: 700;
    .aside{
        float: right;
        font-size:0.625rem;
        font-weight: 400;
        color:#3C5A64;
    }
}
.all-data-subheader{
    margin:0;
    font-size:0.625rem;
    font-weight: 400;
    color:#3C5A64;
    .aside{
        float: right;
    }
}
h3.index-title{
    font-size:0.875rem;
}