.card {
  border: none;
}

.card-body {
  p {
    font-size: 0.875rem;
  }
}
.card-bg-img {
  position: absolute;
  z-index: 0;
  bottom: -20px;
  left: 0;
}

.card-title {
  padding: 0 0 0.25rem 0;
  font-size: 1rem;
  .icon-wrap {
    margin-right: 0.5rem;
  }
}

.cards-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  .card-symptom {
    border: 2px solid $white;
    transition: .3s;

    .card-body {
      padding: 0.5rem 1rem 1rem 1rem;
    }
  
    .card-symptom-title {
      font-size: 0.75rem;
      text-align: center;
      font-weight: 700;
    }

    .card-symptom-info {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -0.25rem;
      margin-bottom: 0.25rem;
      > span {
        justify-content: center;
        flex: 1;
        display: flex;
      }

      .card-symptom-icon {
        font-size: 2rem;
        color: $secondary;
        text-align: center;
        img {
          height: 3.5rem;
          width: 3rem;
        }
      }
      .card-symptom-arrow {
        font-size: 2rem;
        color: $secondary;
      }
      .card-symptom-score {
        font-size: 3rem;
        font-weight: bold;
        font-family: $headings-font-family;
      }
    }
    a {
      color: $dark;
      text-decoration: none;
    }
  }
  .card.card-symptom:hover {
    background-color: #e5f9ff;
  }
}


@include media-breakpoint-only(xs) {
  .cards-container {
    .card-symptom {
      .card-symptom-info {
        .card-symptom-icon {
          img {
            height: 3.5rem;
            width: 2.75rem;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .cards-container .card-symptom .card-symptom-info {
    margin-top: -0.5rem;
    margin-bottom: -0.25rem;
  }
}

@include media-breakpoint-down(sm) {
  .cards-container {
    display: grid;
    gap: 1rem;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}

@include media-breakpoint-only(md) {
  .cards-container {
    display: grid;
    gap: 1rem;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}

@include media-breakpoint-only(lg) {
  .cards-container {
    display: grid;
    gap: 1rem;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}

@include media-breakpoint-only(xl) {
  .cards-container {
    display: grid;
    gap: 1rem;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}

@include media-breakpoint-up(xxl) {
  .cards-container {
    display: grid;
    gap: 1rem;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}



// masthead cards
.exercise-mastheads {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .card {
    border-radius: 0.75rem;
    .card-title {
      background-color: $secondary;
      color: $white;
      position: relative;
      padding: 1rem;
      border-radius: 0.75rem 0.75rem 0 0;
      margin: 0;
      h3 {
        font-size: 1.75rem;
        padding: 0;
        margin: 0;
      }
      .icon-wrap {
        font-size: 1.5rem;
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .card-bg-image {
      min-height: 240px;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      border-radius: 0 0 0.75rem 0.75rem;
      .tracker-info {
        display: block;
        background: linear-gradient(
          177.68deg,
          rgba(0, 0, 0, 0) 30.02%,
          rgba(0, 0, 0, 0.75) 54.24%,
          rgba(0, 0, 0, 0.75) 98.06%
        );
        border-radius: 0 0 0.75rem 0.75rem;
        padding: 3rem 1rem 1rem 1rem;
        color: $white;

        .tracker-title {
          font-size: 0.875rem;
          font-family: $headings-font-family;
          font-weight: 700;
          margin-bottom: 0.25rem;
          display: block;
        }
        .progress {
          background: none;
          border: none;
          gap: 2px;
          width: 75%;
          .progress-bar {
            border-color: transparent;
          }
          .bg-white {
            opacity: 0.33;
          }
        }
        .details {
          font-size: 0.75rem;
        }
      }
    }
  }
  .card-cardio {
    border: none;
    background-image: url(/assets/img/bg-cardio.jpg);
    background-size: cover;
    background-position: 50% 2.5rem;
  }
  .card-strength {
    border: none;
    background-image: url(/assets/img/bg-strength.jpg);
    background-size: cover;
    background-position: 50% 2.5rem;
  }
}

.card-callto.bg-secondary {
  color: $white;
}
.card-callto {
  display: flex;
  padding: 0 1rem;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  .card-callto-icon {
    font-size: 3rem;
  }
  .card-callto-title {
    flex-grow: 1;
    .card-callto-title-title {
      font-weight: bold;
    }
    .card-callto-title-subtitle {
      font-size: 0.75rem;
    }
  }
  .card-callto-arrow{
    font-size:1.5rem;
  }
}
