.bg-symptom-0 {
  background-color: $symptom0;
}

.bg-symptom-1 {
  background-color: $symptom1;
}

.bg-symptom-2 {
  background-color: $symptom2;
}

.bg-symptom-3 {
  background-color: $symptom3;
}

.bg-symptom-4 {
  background-color: $symptom4;
}

.bg-symptom-5 {
  background-color: $symptom5;
  color: $white;
}

.symptom-thermometer-form {
  .form-group {
    font-size: 0.875rem;
  }
  fieldset {
    legend {
      text-align: center;
      font-size: 1rem;
      font-weight: 700;
      color:$dark;
      .symptom-icon {
        display: block;
        margin: 0 auto;
        max-width: 5rem;
        max-height: 5rem;
      }
    }
    .radio-group {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      max-width: 21rem;
      margin: auto;
      .form-check {
        margin: 0.25rem;
        padding: 0;
      }
      .form-check-inline {
        margin: 0.25rem;
      }

      .form-check-inline:nth-child(1) {
        input[type="radio"] {
          &:hover {
            + label {
              background-color: rgb(122, 229, 113, 0.25);
            }
          }
          &:checked {
            + label {
              background-color: $symptom0;
              color: $dark;
            }
            &:hover {
              + label {
                background-color: $symptom0;
              }
            }
          }
        }
      }

      .form-check-inline:nth-child(2) {
        input[type="radio"] {
          &:hover {
            + label {
              background-color: rgb(190, 239, 141, 0.25);
            }
          }
          &:checked {
            + label {
              background-color: $symptom1;
              color: $dark;
            }
            &:hover {
              + label {
                background-color: $symptom1;
              }
            }
          }
        }
      }
      .form-check-inline:nth-child(3) {
        input[type="radio"] {
          &:hover {
            + label {
              background-color: rgb(255, 216, 0, 0.25);
            }
          }
          &:checked {
            + label {
              background-color: $symptom2;
              color: $dark;
            }
            &:hover {
              + label {
                background-color: $symptom2;
              }
            }
          }
        }
      }

      .form-check-inline:nth-child(4) {
        input[type="radio"] {
          &:hover {
            + label {
              background-color: rgb(255, 177, 0, 0.25);
            }
          }
          &:checked {
            + label {
              background-color: $symptom3;
              color: $dark;
            }
            &:hover {
              + label {
                background-color: $symptom3;
              }
            }
          }
        }
      }

      .form-check-inline:nth-child(5) {
        input[type="radio"] {
          &:hover {
            + label {
              background-color: rgb(242, 110, 36, 0.25);
            }
          }
          &:checked {
            + label {
              background-color: $symptom4;
              color: $dark;
            }
            &:hover {
              + label {
                background-color: $symptom4;
              }
            }
          }
        }
      }

      .form-check-inline:nth-child(6) {
        input[type="radio"] {
          &:hover {
            + label {
              background-color: rgb(226, 80, 80, 0.25);
            }
          }
          &:checked {
            + label {
              background-color: $symptom5;
              color: $white;
            }
            &:hover {
              + label {
                background-color: $symptom5;
              }
            }
          }
        }
      }
    }

    .radio-group-yn {
      .form-check-inline:nth-child(1) {
        input[type="radio"] {
          &:hover {
            + label {
              background-color: $symptom0;
            }
          }
          &:checked {
            + label {
              background-color: $symptom0;
            }
            &:hover {
              + label {
                background-color: $symptom0;
              }
            }
          }
        }
      }
      .form-check-inline:nth-child(2) {
        input[type="radio"] {
          &:hover {
            + label {
              background-color: $symptom5;
              color: $white;
            }
          }
          &:checked {
            + label {
              background-color: $symptom5;
              color: $white;
            }
            &:hover {
              + label {
                background-color: $symptom5;
                color: $white;
              }
            }
          }
        }
      }
      input[type="radio"] {
        opacity: 0;
        width: 0px;
        + label {
          position: relative;
          display: inline-block;
          cursor: pointer;
          background-color: $light;
          font-size: 1.75rem;
          width: 5rem;
          height: 3.125rem;
          line-height: 3.125rem;
          border-radius: $border-radius;
        }
      }
    }

    input[type="radio"] {
      opacity: 0;
      width: 0px;
      + label {
        position: relative;
        display: inline-block;
        cursor: pointer;
        background-color: $light;
        font-size: 2.25rem;
        width: 3.125rem;
        height: 3.125rem;
        line-height: 3.125rem;
        border-radius: $border-radius;
      }

      &:hover {
        + label {
          background-color: rgb(6, 94, 184, 0.25);
          color: $black;
        }
      }

      &:checked {
        + label {
          color: $white;
          background: $primary;
        }
        &:hover {
          + label {
            background-color: $primary;
          }
        }
      }
      &:focus {
        + label {
          box-shadow: 0 0px 8px $primary;
        }
      }
    }
  }
  .form-instructions {
    max-width: 21rem;
    margin: 0rem auto 1rem auto;
    color: $secondary;
  }

  .sympton-desc {
    text-align: center;
    color: $secondary;
  }

  .symptom-desc-low {
    display: block;
    margin-left: -12px;
  }

  .symptom-desc-high {
    display: block;
    margin-right: -12px;
  }
}

.table-symptoms {
  margin: 2rem 0 10rem 0;
  thead {
    background-color: $light !important;
    border-radius: $border-radius 0 0 0;
    th {
      border: none;
      text-align: center;
    }
    th:first-child {
      border-radius: $border-radius 0 0 0;
    }
    th:last-child {
      border-radius: 0 $border-radius 0 0;
      background-color: $light !important;
      color: $white;
    }
  }
  th {
    background-color: $light !important;
    border-color: $white;
  }
  td {
    text-align: center;
    border-color: $light;
  }

  tbody {
    tr {
      td:last-child {
        border-color: $white;
      }
    }
    tr:last-child {
      td {
        border-bottom: 1px solid $light;
      }
      th:first-child {
        border-radius: 0 0 0 $border-radius;
      }
      td:last-child {
        border-radius: 0 0 $border-radius 0;
        border: none;
      }
    }
  }
}

.arrow-right {
  display: block;
  position: relative;
  margin: 10px 0;
  height: 0;
  border: 4px solid;
  border-image-slice: 1;
  border-width: 4px;
  border-image-source: linear-gradient(to left, #DE3535, #FFB100);
  border-top: 0;
  border-left:0;
  border-right: 0;
}

.arrow-right::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 12px solid #DE3535;
  position: absolute;
  right: -12px;
  top: -6px;
}

.arrow-left {
  display: block;
  position: relative;
  margin: 10px 0;
  height: 0;
  border: 4px solid;
  border-image-slice: 1;
  border-width: 4px;
  border-image-source: linear-gradient(to right, #7AE571, #FFD800);
  border-top: 0;
  border-left:0;
  border-right: 0;
}
.arrow-left::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 12px solid #7AE571;
  position: absolute;
  left: -12px;
  top: -6px;
}

.tracker-list li a:hover .progress-symptom {
  background-color: $white;
  .line {
    background-color: $light;
  }
}

.tracker-list li a.symptom-list {
  align-items: flex-start;
  padding: 30px 10px;
  .tracker-icon {
    line-height: 3.4rem;
  }
  .tracker-link > .icon-wrap.symptom-small-icon {
    color: $dark;
  }
  .symptom-feedback-title {
    color: $black;
    font-weight: 700;
  }
  .symptom-feedback-info {
    color: $dark;
  }
  p {
    margin-bottom: 0.75rem;
  }
}

.sympton-icon-highlight {
  text-align: center;
  flex: 1;
  img {
    height: 100%;
    width: 100%
  }
}

.health-status-score {
  display: inline-flex;
  justify-content: center;  
  height: 5.25rem;   

}

.symptom-feedback-info {
  font-size: 0.75rem;
  max-width: 32rem;
  margin: 0 auto;
  display: block;
  p {
    margin-bottom: 0.5em;
  }
}

p.symptom-feedback-title {
  color: $black;
  font-weight: 700;
  margin-top: revert;
}

// sputum

.radio-group-sputum {
  .form-check-inline {
    .form-check-label {
      position: relative;
      .sputum-icon {
        position: absolute;
        top: 0rem;
        left: 0.75rem;
        font-size: 2rem;
      }
    }
  }

  .form-check-inline:nth-child(1) {
    .form-check-label {
      background-color: $sputum1;
      position: relative;
    }
  }
  .form-check-inline:nth-child(2) {
    .form-check-label {
      background-color: $sputum2;
    }
  }
  .form-check-inline:nth-child(3) {
    .form-check-label {
      background-color: $sputum3;
    }
  }
  .form-check-inline:nth-child(4) {
    .form-check-label {
      background-color: $sputum4;
    }
  }
  .form-check-inline:nth-child(5) {
    .form-check-label {
      background-color: $difficulty5;
      background-color: $sputum5;
    }
  }
}
.symptom-thermometer-form {
  .chosen {
    .form-check-inline {
      .form-check-label {
        background-color: $white !important;
        position: relative;
      }
      .form-check-label::after {
        content: " ";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0.875rem;
        height: 0.875rem;
        margin-top: -0.375rem;
        margin-left: -0.375rem;
        border-radius: 0.1875rem;
      }
    }
    .form-check-inline:nth-child(1) {
      .form-check-label::after {
        background-color: $symptom0;
      }
      input[type="radio"] {
        &:checked {
          + label {
            position: relative;
            background-color: $symptom0 !important;
            color: $white;
          }
          + label.form-check-label::after {
            display: none !important;
          }
        }
      }
    }
    .form-check-inline:nth-child(2) {
      .form-check-label::after {
        background-color: $symptom1;
      }
      input[type="radio"] {
        &:checked {
          + label {
            position: relative;
            background-color: $symptom1 !important;
            color: $white;
          }
          + label.form-check-label::after {
            display: none !important;
          }
        }
      }
    }
    .form-check-inline:nth-child(3) {
      .form-check-label::after {
        background-color: $symptom2;
      }
      input[type="radio"] {
        &:checked {
          + label {
            position: relative;
            background-color: $symptom2 !important;
            color: $white;
          }
          + label.form-check-label::after {
            display: none !important;
          }
        }
      }
    }
    .form-check-inline:nth-child(4) {
      .form-check-label::after {
        background-color: $symptom3;
      }
      input[type="radio"] {
        &:checked {
          + label {
            position: relative;
            background-color: $symptom3 !important;
            color: $white;
          }
          + label.form-check-label::after {
            display: none !important;
          }
        }
      }
    }
    .form-check-inline:nth-child(5) {
      .form-check-label::after {
        background-color: $symptom4;
      }
      input[type="radio"] {
        &:checked {
          + label {
            position: relative;
            background-color: $symptom4 !important;
            color: $white;
          }
          + label.form-check-label::after {
            display: none !important;
          }
        }
      }
    }
    .form-check-inline:nth-child(6) {
      .form-check-label::after {
        background-color: $symptom5;
      }
      input[type="radio"] {
        &:checked {
          + label {
            position: relative;
            background-color: $symptom5 !important;
            color: $white;
          }
          + label.form-check-label::after {
            display: none !important;
          }
        }
      }
    }
  }
}
// sputum...
.radio-group-sputum.chosen {
  .sputum-icon {
    // display: none;
  }
  .form-check-inline:nth-child(1) {
    .form-check-label::after {
      background-color: $sputum1;
    }
    input[type="radio"] {
      &:checked {
        + label {
          position: relative;
          background-color: $sputum1 !important;
          color: $white;
        }
        + label.form-check-label::after {
          display: none !important;
        }
      }
    }
  }
  .form-check-inline:nth-child(2) {
    .form-check-label::after {
      background-color: $sputum2;
    }
    input[type="radio"] {
      &:checked {
        + label {
          position: relative;
          background-color: $sputum2 !important;
          color: $white;
        }
        + label.form-check-label::after {
          display: none !important;
        }
      }
    }
  }
  .form-check-inline:nth-child(3) {
    .form-check-label::after {
      background-color: $sputum3;
    }
    input[type="radio"] {
      &:checked {
        + label {
          position: relative;
          background-color: $sputum3 !important;
          color: $white;
        }
        + label.form-check-label::after {
          display: none !important;
        }
      }
    }
  }
  .form-check-inline:nth-child(4) {
    .form-check-label::after {
      background-color: $sputum4;
    }
    input[type="radio"] {
      &:checked {
        + label {
          position: relative;
          background-color: $sputum4 !important;
          color: $white;
        }
        + label.form-check-label::after {
          display: none !important;
        }
      }
    }
  }
  .form-check-inline:nth-child(5) {
    .form-check-label::after {
      background-color: $sputum5;
    }
    input[type="radio"] {
      &:checked {
        + label {
          position: relative;
          background-color: $sputum5 !important;
          color: $white;
        }
        + label.form-check-label::after {
          display: none !important;
        }
      }
    }
  }
}

.bg-sputum-1 {
  background-color: $sputum1 !important;
}

.bg-sputum-2 {
  background-color: $sputum2 !important;
}

.bg-sputum-3 {
  background-color: $sputum3 !important;
}

.bg-sputum-4 {
  background-color: $sputum4 !important;
}

.bg-sputum-5 {
  background-color: $sputum5 !important;
}

h2.symptom-score{
  font-size:4rem;
  flex: 1;
}

.symptoms-chart{
  display: flex;
  justify-content: center;
}

.symptoms-chart-arrow {
  width: 350px;
  margin:3px auto;
  position: relative;
  height:10px;
}

.symptoms-chart-line {
  position: absolute;
  top: 5px;
  width: 340px;
  background: #D2D5D9;
  height: 2px;
  float: left;
}

.symptoms-chart-point {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 10px solid #D2D5D9;
  float: right;
}



// arrows for scales
.normal-indicator-container {
  max-width: 21rem;
  display: flex;
  justify-content: space-between;
  margin:0 auto;
  > div {
    min-width: 3.125rem;
    text-align: center;
  }
}

.normal-indicator-container{
  font-size:1.5rem;
}

.symptom-high-icon{
  position: absolute;
  top:0.25rem;
  right:0.5rem;
}