.fade-in {
  animation: fadeIn ease-in-out 1s;
  -webkit-animation: fadeIn ease-in-out 1s;
  -moz-animation: fadeIn ease-in-out 1s;
  -o-animation: fadeIn ease-in-out 1s;
  -ms-animation: fadeIn ease-in-out 1s;
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
}
}

@keyframes slideInDown {
from {
  transform: translate3d(0, -100%, 0);
  visibility: visible;
}

to {
  transform: translate3d(0, 0, 0);
}
}

.slideInDown {
animation-name: slideInDown;
animation-fill-mode: both;
animation-duration: 0.5s;
}

.animate-500{
animation-duration: 0.5s !important;

}
.animate-250{
animation-duration: 0.5s !important;

}
@keyframes slideInRight {
from {
  transform: translate3d(100%, 0, 0);
  visibility: visible;
}

to {
  transform: translate3d(0, 0, 0);
}
}
.slide-in-right{
animation-name: slideInRight ease-in-out 0.1s;
-webkit-animation: slideInRight ease-in-out 0.1s;
-moz-animation: slideInRight ease-in-out 0.1s;
-o-animation: slideInRight ease-in-out 0.1s;
-ms-animation: slideInRight ease-in-out 0.1s;
}

@-webkit-keyframes reveal-award{
0% {
  -webkit-transform: scale(2);
          transform: scale(2);
  -webkit-filter: blur(4px);
          filter: blur(4px);
  opacity: 0;
}
100% {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-filter: blur(0px);
          filter: blur(0px);
  opacity: 1;
}
}
@keyframes reveal-award {
0% {
  -webkit-transform: scale(2);
          transform: scale(2);
  -webkit-filter: blur(4px);
          filter: blur(4px);
  opacity: 0;
}
100% {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-filter: blur(0px);
          filter: blur(0px);
  opacity: 1;
}
}