.pick-list{
    margin:0;
    padding:0;
     >li{
        list-style: none;
        padding: .75rem 1.5rem .75rem .75rem;
        color:$dark;
        cursor: pointer;
        margin-bottom: .75rem;
        border-radius: $border-radius;
        background-color: $light;
        position: relative;
        font-size: 0.875rem;

        &:hover {
            background-color: #E5F9FF;
        }

        .pick-icon{
            margin-right:1rem;
        }
        .expand-icon{
            position: absolute;
            right:.75rem;
            font-size:1rem;
        }
        ul{
            margin:.5rem 0 0 2rem;
            padding:0;
            font-size:.75rem;
            li{
                list-style: none;
                padding:.5rem;
            }
        }
    }
    li.open{
        .expand-icon, .pick-item{
            color:$primary;
        }
    }


    li.active{
        background-color: $secondary;
        color:$white;
    }

}

.pick-list.strength-picks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: flex-start;

    >li.strength-pick {
        display: block;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: 49%;
        align-self: auto;
        order: 0;
        text-align: center;
        padding: 0.75rem;

        .strength-icon {
            display: block;
            margin: 0.5rem 0 0 0;
            svg {
                width: 4.5rem;
                height: 4.5rem;
            }
        }

        .pick-icon{
            margin-right:0.5rem;
        }
    }
    li.strength-pick.active {
        .strength-icon {
            svg {
                path {
                    fill: $white;
                }
            }
        }
    }
        
}
