html {
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth;
}
.fh {
  min-height: 100vh;
}
.fh-nav {
  min-height: calc(100vh - 60px);
}
.fh-nav-subnav {
  min-height: calc(100vh - 151px);
}
.fh-nav-subnav-stagenav {
  min-height: calc(100vh - 197px);
}
.content-container {
  padding: 20px;
  .row {
    margin-top: 1rem;
  }
}

.main-content-area {
  padding: 1rem;
  margin-bottom: 1rem;
}

.main-content-area.sub-listing, .main-content-area.sub-page {
  padding: 0rem 1rem 1rem 1rem;
  background-color: $white;
  .tracker-list li {
    border: none!important;
    a {
      border-radius: 0rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  .main-content-area.sub-listing {
    padding: 0 1rem 1rem 1rem;
  }
  .main-content-area.sub-page {
    padding: 0 1rem 1rem 1rem;
  }
}

@include media-breakpoint-up(sm) {
  .main-content-area.sub-listing, .main-content-area.sub-page {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}

.btn-fixed {
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-box-shadow: 0px 0px 16px 2px rgba(255,255,255,0.9);
  -moz-box-shadow: 0px 0px 16px 2px rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 16px 2px rgba(255,255,255, 0.9);
}

.container-narrow{
  max-width: 780px;
  margin:0 auto;
}
.bg-dark {
  background:$dark;
  color: $white;
  a {
    color: $white;
  }
  hr {
    border-color: rgba($white, 0.5);
  }
}
.bg-grey {
  background-color: #D2D5D9;
}

.bg-field {
  background-color: rgba(6,10,11,0.1);
}
.bg-menu-bg {
  background-color: $dark;
}
.text-menu-bg {
  color: $dark;
}
.text-light-grey {
  color: #D2D5D9;
}
.divider {
  border-top-width: 1px;
}
.divider-light {
  border-color: rgba(255, 255, 255, 0.5);
}
.drop-shadow {
  box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.1);
}

a .drop-shadow:active {
    box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.2);
    transform: translate(3px, 3px);
  }

  .pointer{
    cursor: pointer;
  }
// extra bgs
@each $color, $value in $colors {
  .bg-#{$color} {
    background-color: rgba($value, 1) !important;
  }
}
@each $color, $value in $theme-colors {
  .bg-#{$color}-dark {
    background-color: rgba(darken($value, 14), 1);
  }
}
@each $color, $value in $theme-colors {
  .bg-#{$color}-darker {
    background-color: rgba(darken($value, 24), 1);
  }
}

// form addons
.inner-addon {
  position: relative;
}
.inner-addon .addon {
  position: absolute;
  padding: 10px;
  z-index: 20;
}
.inner-addon .addon.btn {
  padding: 5px;
  margin: 5px;
  text-align: center;
}
.left-addon .addon {
  left: 0px;
}
.right-addon .addon {
  right: 0px;
}
.left-addon input {
  padding-left: 30px;
}
.right-addon input {
  padding-right: 30px;
}

a.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.nmh{
  min-height: auto!important;
}
.icon-xl{
  font-size:3rem;
}

.icon-button {
margin-top: -0.2rem;
padding: 0.2rem 0.5rem;
display: inline-block;
border-radius: $border-radius;
transition: 0.3s;
  &:hover {
    background-color: rgb(255,255,255,0.1);
  }
  &:active {
    background-color: rgb(255,255,255,0.2);
  }
}

.cc-window {
  background-color: $dark!important;
  font-size: 0.875rem!important;
  font-family: 'Frutiger'!important;
  box-shadow: inset 0px 2px 5px 0px rgba(0,0,0,0.25);
}

.cc-btn {
  border-radius: $border-radius!important;
  color: $primary!important;
  font-weight: 300!important;

&:hover {
  background-color: #ececec!important;
}
}
.cc-revoke{
  display:none !important;
}
// media queries
@include media-breakpoint-up(lg) {

  .content-wrap-np{
    padding:0;

  }
  router-outlet ~ * {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

@include media-breakpoint-down(lg) {
.content-wrap {
  //padding-bottom: 4rem;
}
}

.item-locked{
  opacity: 0.40;
}

.last-update-text{
  font-size:0.625rem;
  color: $secondary;
}

.container-getting-started {
    max-width: 780px;

    .more-resources {
      padding: 2rem 0rem 0rem 0rem;
    }

    .card-secondary {
      background-color: $secondary;
      .card-body {
        p {
          color: #fff;
          font-size: 0.875rem;
        }
        .todo-progress {
          padding: 0rem;
          color: #fff;
          background-color: $secondary;
          padding-top: 1rem;
          margin-top: 0.5rem;
          border-top: 1px solid rgba(255, 255, 255, 0.25);
          .progress-bar.bg-white {
            background-color: rgba(255, 255, 255, 0.25)!important;
          }
          .progress-bar {
            .icon-wrap {
              font-size: 0.75rem;
            }
          }
        }
      }
    }
}

@media (max-width: 780px) {
  .container-getting-started {
    .internal-app {
      padding: 0rem;
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .ii-form .form-group {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .quiz-question {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .w-100 {
      margin-left: 1rem;
      margin-right: 1rem;
      width: calc(100% - 2rem)!important;
    }
}
}

.container-getting-started{
  .container-resource-page{
    p{
      a{
        color:$body-color;
        text-decoration: none;
        pointer-events: none;
      }
    }
  }
}