$difficulty1: #7AE571;
$difficulty2: #BEEF8D;
$difficulty3: #FFB100;
$difficulty4: #F26E24;
$difficulty5: #DE3535;

$difficulty-colors: (
  1: $difficulty1,
  2: $difficulty2,
  3: $difficulty3,
  4: $difficulty4,
  5: $difficulty5,
);

@each $color, $value in $difficulty-colors {
  .text-difficulty-#{$color} {
    color: rgba($value, 1) !important;
  }
  .bg-difficulty-#{$color} {
    background-color: rgba($value, 1) !important;
  }
  .text-wellbeing-#{$color} {
    color: rgba($value, 1) !important;
  }
  .text-symptom-#{$color} {
    color: rgba($value, 1) !important;
  }
}

legend {
  text-align: center;
  font-size: 1rem;
}

.input-difficulty {
  .radio-group {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .form-check {
      padding: 0;
    }
    .form-check-inline {
      margin: 0;
      .form-check-label {
        color: $dark;
        border: 0.125rem solid $white;
        border-radius: 0.625rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    input[type="radio"] {
      opacity: 0;
      width: 0rem;
      + label {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        background-color: $white;
        font-size: 1rem;
        width: 3.125rem;
        height: 3.125rem;
        border-radius: $border-radius;
        color: $dark;
      }

      &:hover {
        + label {
          background-color: rgb(6, 94, 184, 0.25);
          color: $black;
        }
      }

      &:checked {
        + label {
          color: $white;
          background: $primary;
        }
        &:hover {
          + label {
            background-color: $primary;
          }
        }
      }
      &:focus {
        + label {
          box-shadow: 0 0rem 0.5rem $primary;
        }
      }
    }
  }
}

.input-difficulty .radio-group {
  .form-check-inline {
    .form-check-label {
      font-size: 2rem;
      color: $white;
    }
  }

  .form-check-inline:nth-child(1) {
    input[type="radio"] {
      + label {
        background-color: $difficulty1;
      }
      &:hover {
        + label {
          background-color: rgb(242, 226, 78, 0.25);
        }
      }
      &:checked {
        + label {
          background-color: $difficulty1;
          color: $white;
          border-color: $dark;
        }
        &:hover {
          + label {
            background-color: $difficulty1;
          }
        }
      }
    }
  }
  .form-check-inline:nth-child(2) {
    input[type="radio"] {
      + label {
        background-color: $difficulty2;
      }
      &:hover {
        + label {
          background-color: rgb(235, 185, 42, 0.25);
        }
      }
      &:checked {
        + label {
          background-color: $difficulty2;
          color: $white;
          border-color: $dark;
        }
        &:hover {
          + label {
            background-color: $difficulty2;
          }
        }
      }
    }
  }

  .form-check-inline:nth-child(3) {
    input[type="radio"] {
      + label {
        background-color: $difficulty3;
      }
      &:hover {
        + label {
          background-color: rgb(251, 150, 38, 0.25);
        }
      }
      &:checked {
        + label {
          background-color: $difficulty3;
          color: $white;
          border-color: $dark;
        }
        &:hover {
          + label {
            background-color: $difficulty3;
          }
        }
      }
    }
  }

  .form-check-inline:nth-child(4) {
    input[type="radio"] {
      + label {
        background-color: $difficulty4;
      }
      &:hover {
        + label {
          background-color: rgb(251, 102, 32, 0.25);
        }
      }
      &:checked {
        + label {
          background-color: $difficulty4;
          color: $white;
          border-color: $dark;
        }
        &:hover {
          + label {
            background-color: $difficulty4;
          }
        }
      }
    }
  }

  .form-check-inline:nth-child(5) {
    input[type="radio"] {
      + label {
        background-color: $difficulty5;
      }
      &:hover {
        + label {
          background-color: rgb(235, 18, 24, 0.25);
        }
      }
      &:checked {
        + label {
          background-color: $difficulty5;
          color: $white;
          border-color: $dark;
        }
        &:hover {
          + label {
            background-color: $difficulty5;
          }
        }
      }
    }
  }
}
.input-difficulty .chosen {
  .form-check-inline {
    .form-check-label {
      font-size: 2rem;
      color: #ccc;
      background-color: $white !important;
      border: none;
    }
  }
  .form-check-inline:nth-child(1) {
    input[type="radio"] {
      &:checked {
        + label {
          background-color: $difficulty1 !important;
          color: $white;
          border-color: $dark;
        }
        &:hover {
          + label {
            background-color: $difficulty1 !important;
          }
        }
      }
    }
  }
  .form-check-inline:nth-child(2) {
    input[type="radio"] {
      &:checked {
        + label {
          background-color: $difficulty2 !important;
          color: $white;
          border-color: $dark;
        }
        &:hover {
          + label {
            background-color: $difficulty2 !important;
          }
        }
      }
    }
  }
  .form-check-inline:nth-child(3) {
    input[type="radio"] {
      &:checked {
        + label {
          background-color: $difficulty3 !important;
          color: $white;
          border-color: $dark;
        }
        &:hover {
          + label {
            background-color: $difficulty3 !important;
          }
        }
      }
    }
  }
  .form-check-inline:nth-child(4) {
    input[type="radio"] {
      &:checked {
        + label {
          background-color: $difficulty4 !important;
          color: $white;
          border-color: $dark;
        }
        &:hover {
          + label {
            background-color: $difficulty4 !important;
          }
        }
      }
    }
  }
  .form-check-inline:nth-child(5) {
    input[type="radio"] {
      &:checked {
        + label {
          background-color: $difficulty5 !important;
          color: $white;
          border-color: $dark;
        }
        &:hover {
          + label {
            background-color: $difficulty5 !important;
          }
        }
      }
    }
  }
}
