.badge-lg{
  font-size:1rem;
}
.badge-xl{
  font-size:3rem;
}
.badges-section{
  text-align: center;
  margin-bottom:1rem;
  h3{
    font-size:0.875rem;
    color:#3C5A64;
  }
  .badges-container{
    overflow-x:scroll;
    display:flex;
    justify-content: flex-start;
    gap:1rem;
    padding:0rem 0rem 1rem 1rem;
  }
}

