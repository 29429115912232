.tracker-list {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    border: 2px solid $white!important;
    > a,
    .flex-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: stretch;
      flex-flow: row nowrap !important;
      justify-content: flex-start;
      border-bottom: 1px solid #ebebeb;
      padding: 1rem;
      transition: 0.3s;
      text-decoration: none;
      color: $dark;
      border-radius: 0.5rem;
    }
    > a:active,
    > a:hover {
      text-decoration: none;
      background-color: #E5F9FF;
    }
    .tracker-icon {
      font-size: 2.875rem;
      color: #425563;
      margin-right: 1rem; display: flex;
      justify-content: center;
      align-items: center;
    }
    .tracker-num{ 
      display: flex;
      justify-content: center;
        margin-right: 1rem;
      .num{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(6,10,11,0.1);;
        border-radius: 50%;
        width:24px;
        height:24px;
        font-family: $headings-font-family;
        font-size: 0.875rem;
        font-weight: 700;
        
      }
      .icon-wrap{
        font-size:1.5rem;
      }
    }
    .icon-more {
      font-size: 1.5rem;
      color: #3C5A64;
      align-items: center;
    }
    .tracker-label {
      background-color: $dark;
      color: $white;
      font-size: 1.125rem;
      margin-right: 1rem;
      padding: 8px 5px 5px 5px;
      min-width: 40px;
      min-height: 40px;
      text-align: center;
      border-radius: $border-radius;
      line-height: 0.7;
      .small {
        font-size: 0.75rem;
        line-height: 0.75rem;
        margin: 0;
      }
    }
    .tracker-label-lg {
      font-size: 1.5rem;
      margin-right: 1rem;
    }
    .tracker-info {
      flex-grow: 1;
      .tracker-title {
        display: block;
        font-size: 0.875rem;
        font-weight: 700;

        > .icon-symptom {
          color: $dark;
          margin-right: 0.5rem;
        }
        > .icon-wrap {
        }
      }
      .details {
        display: block;
        color: #3C5A64;
        font-size: 0.75rem;
        margin-top: 0px;
      }
      .details-sm {
        display: block;
        font-size: 0.625rem;
        margin-top:10px;
      }
      .progress {
        margin: 5px 40px 4px 0;
      }
      .small {
        color: $dark;
      }
    }
    .tracker-info-lg {
      font-size: 1rem;
    }
  }
  .new{
    background-color: #fffaad;
  }
  li:first-child {
    border-top: 1px solid #ebebeb;
  }
}
.bg-bmi {
  background: $white;
  background: linear-gradient(
    90deg,
    $yellow 13%,
    $green 18%,
    $green 50%,
    $orange 62%,
    $orange 83%,
    $red 88%
  );
  position: relative;
  .dot {
    background-color: $white;
    width: 9px;
    height: 9px;
    position: absolute;
    border-radius: 50%;
  }
}

.tracker-list-no-bt {
  li:first-child {
    border-top: none;
  }
}
.tracker-list-symptoms {
  li {
    border-bottom: 1px solid #ebebeb;
    > a,
    .flex-wrap {
      border-bottom: none;
    }
    .symptom-feedback {
      padding-left: 0rem;
      padding-right: 2.5rem;
      margin-top: 1rem;
    }
  }
}

// mastheads
.tracker-list-mastheads {
  li {
    position: relative;
    background-color: #e9ecec;
    padding: 1rem;
    border:none;
    a{
      border:none;
      position: relative;
      z-index: 1;
    }
    > a:hover {
      text-decoration: none;
      background: none;
    }
    .tracker-headline {
      font-size: 1.75rem;
      font-family: $headings-font-family;
      font-weight: 600;
      padding: 0rem 0 1rem 0;
      display: block;
    }
    .icon-more {
      top:20px;
      right:10px;
      position: absolute;
      min-width: 25%;
      text-align: right;
    }
  }
  li.cardio {
    //background-image:url(/assets/img/cardio-yellow.jpg);
    //background-position:100% 100%;
    border-bottom: 50px solid #d2c198;
    min-height: calc(33% - 11px);
  }
  li.strength {
    border-bottom: 50px solid #17778e;
    min-height: calc(33% - 11px);
  }
  li.challenges {
    border-bottom: 50px solid #e8cac5;
    min-height: calc(33% - 11px);
  }
}

.tracker-list-heading {
  font-size: 0.75rem;
  padding: 2rem 1rem 0.5rem 1rem;
  margin: 0;
  font-weight: 700;
}

.tracker-list-padded {
  margin: 0;
  overflow: hidden;
  li:first-child {
    border: none;
  }
  li {
    border: none;
    margin: 0rem 0rem 1rem 0rem;
    background-color: $white;
    border-radius: $border-radius;
    .tracker-icon {
      font-size: 32px;
      color: $primary;
      margin-left: 0rem;
      margin-right: 1rem;
    }
    .tracker-link {
      font-weight: 700;
    }

    > a,
    .flex-wrap {
      border: none;
      padding: 1rem;
    }
  }
}

.tracker-list-bg-img {
  position: absolute;
  z-index: 0;
  bottom:0;
  left:0;
}
.tracker-list-bg-img-end {
  left: auto;
  right:0;
}

.cardio{
  .tracker-list-bg-img{
    bottom:-22px;
  }
}
.strength {
  .tracker-list-bg-img{
    bottom:-25px;
  }
}
.challenges {
  .tracker-list-bg-img{
    bottom:-19px;
  }
}

.tracker-key {
  font-size: 0.75rem;
  color: $secondary;
  text-align: center;
  border-top: 1px solid $white;
  margin-top: 1rem;
  padding-top: 1rem;
  span {
    font-size: 1rem;
  }
}