.status-calendar {
  display: grid;
  grid-template-columns: 1fr;
  margin: 0.25rem auto 1rem auto;
  max-width: 400px;

  .status-calendar-header {
    font-family: $headings-font-family;
    font-weight: 700;
    font-size: 0.625rem;
  }

  .status-calendar-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0.125rem 0.125rem;
    gap: 0.125rem 0.125rem;
    margin-bottom: 0.5rem;
    text-align: center;

    .status-calendar-day {
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
      font-size: 0.625rem;
      text-align: center;
      line-height: 1.25rem;
      font-weight: 700;
    }

    .status-calendar-day.incomplete {
      background-color: $white;
    }

    .status-calendar-day.complete {
      background-color: $secondary;
      color: $white;
    }
  }
}

.status-calendar-row.status-calendar-header {
  margin-bottom: 0rem;
  grid-template-rows: 1.25rem;
}

.cal-check {
  font-size: 1.25rem;
}

.status-badge {
  background-color: $white;
  border-radius: 0.625rem;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  .status-badge-icon {
    color: $primary;
    font-size: 1.5rem;
    margin-right: 0.625rem;
  }

  .status-badge-text {
    text-align: center;

    .status-badge-title {
      font-family: $headings-font-family;
      color: $dark;
      font-size: 0.625rem;
      font-weight: 700;
      text-transform: uppercase;
    }

    .status-badge-status {
      font-size: 1.063rem;
      font-weight: 400;
      color: $dark;

      /* span {
          font-size: 0.6875rem;
          font-weight: 400;
        }*/
    }
  }
}

.status-badge-sm {
  .status-badge-text {
    .status-badge-title {
      font-size: 0.625rem;
    }
    .status-badge-status {
      font-size: 0.75rem;
    }
  }
}

.status-badge-dark{
  background-color:$secondary;
  color:$white !important;
  .status-badge-text {

    .status-badge-title {
      color: $white;
    }

    .status-badge-status {
      color: $white;
    }
  }
}


.outside-month{
  opacity: 0.33;
}