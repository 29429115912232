.strength-icon-active {
  opacity: 1;
}
.strength-icon-inactive {
  opacity: 0.25;
}
.strength-exercise-key{
    justify-content: space-between;
    max-width: 25rem;
    font-size:0.625rem;
    margin: 0 auto 0rem auto;
    svg{
        height:1.75rem;
    }
}
.strength-exercises-detailed{
    font-size:0.75rem;
    line-height: 1rem;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    row-gap: 0.75rem;
    column-gap: 0.25rem;
    max-width: 28.75rem;
    margin: 0 auto 0 auto;
    .strength-icon svg{
        height:1.75rem;

    }
    .strength-exercises-item {
        width:6.5rem;
        display: flex;
    }

    .strength-icon-all {
        width: 1.25rem;
        text-align: center;
        margin-right: 0.25rem;
    }
}

.strength-view-details{
    display:flex;
    justify-content: space-around;
    gap:0.625rem;
    align-items: center;
}
.strength-view-title{
    text-align: center;
    margin-bottom:0.5rem;
    h3{
        margin:0;
        padding:0;
        font-size:0.875rem;
        font-weight: 700;
    }
    h4{
        margin:0.125rem 0 0 0;
        padding:0;
        font-size:0.625rem;
        font-weight: 400;
        font-family: $font-family-sans-serif;
        color:$secondary;
    }
}
.strength-view-difficulty{
    text-align: center;
    margin-top:0.5rem;
    font-size: 0.75rem;
    
    h5{
        font-weight: 700;
        font-size: 0.625rem;
        line-height: 1rem;
        text-transform: uppercase;
        margin:0 0 -0.25rem 0;
        padding:0;
        font-family: $headings-font-family;
    }

    .difficulty-circle{
        display:inline-block;
        width:0.875rem;
        height:0.875rem;
        border-radius: 50%;
        border:1px solid $white;
    }
    .difficulty-text{
        font-size:0.75rem;
        margin-left: 0.25rem;
        line-height: 1.5rem;
    }
}

.difficulty-circle{
    display:inline-block;
    width:0.625rem;
    height:0.625rem;
    border-radius: 50%;
    border:1px solid $white;
}
.difficulty-text{
    font-size:0.625rem;
    margin-left: 0.25rem;
}

@include media-breakpoint-down(sm) {
.strength-exercises-detailed{
    max-width: 14.25rem;
    }
}
.strength-view-icon{
    margin-top: -1.25rem;
    svg {height:4.5rem;width:2.75rem;}
}

.difficulty-icon-alignment {
    line-height: 1rem;
    vertical-align: middle;
}


.strength-summary {
    .card-body {
        padding: 0.5rem 1rem;
    }

    .strength-view-icon {
        margin-top: -1rem;
    }

    .status-badge-status, .difficulty-text {
        font-size: 0.875rem;
    }

    .strength-view-details {
        align-items: start;
    }

    .strength-view-difficulty {
        margin-top: 0rem;
    }

    .status-badge {
        padding: 0rem;
    }

    .strength-view-icon svg {
        width: 2rem;
        height: 2rem;
    }
}