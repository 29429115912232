
.navbar-main {
    min-height: 60px;

    .back-link{
      z-index: 10;
      position: absolute;
      top:0.25rem;
      left:0;
       color:$white;
       font-size:1.125rem;
       line-height:1.125rem;
       display: inline;
       padding:1rem;
       border-radius: $border-radius;
       &:hover {
         background-color: rgb(255,255,255,0.1);
       }
       &:active {
         background-color: rgb(255,255,255,0.3)!important;
         transition: 0s;
       }
   }

    .navbar-brand {
      font-family: $headings-font-family;
      font-weight: normal;
      display: inline-block;
      top: 0px;
      padding:0;
      text-align: center;
      img {
        height: 30px;
      }
    }
    h1{
      z-index: 1;
      color:$white;
      font-size: 1.25rem;
      font-weight: 600;
      padding-left: 35px;
    padding-right: 35px;
    text-wrap: wrap;
    display: flex;
    align-items: center;
    
    color: #fff;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.4rem;
      margin:0;
      position: relative;
      top:0px;
      .icon-wrap{
        margin-right:10px;
        font-size: 1.5rem;
      }
    }
    .btn-nav-menu{
      font-size:1.25rem;
      color:$white;
      border: none;
      background-color: transparent;
      text-align: right;
      padding: 1rem;
      border-radius: 0.5rem;
      line-height: 1.25rem;
    }
    .nhs-logo{
      position: relative;
      top:5px;
      img{
        height:17px;
        width: 42px;
      }
    }
  }

  // media queries
@include media-breakpoint-up(lg) {
    
  .navbar-main {
    margin-top:1rem;
    border-radius: $border-radius $border-radius 0 0;
    .nhs-logo, .btn-nav-menu{

    }
  }


}

@include media-breakpoint-down(sm) {
  .navbar-main {    
  .back-link{
    left:2px;
}
  }
}


.navbar-main.navbar-light{
  color:#222529;
 h1{
   color:#222529;

  }
  .back-link, .btn-nav-menu{
    color:#3C5A64;
  }
}

.btn-group-navbar-end{
  position: absolute;
  right:2px;
  top: 0.25rem;
}

.navbar-brand{
  .exercise-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0 auto;
    height:24px;
    width:24px;
    svg{
      .cls-2{
        fill:#fff;
      }
    }
  }
}