.msg-details {
  padding: 0 1rem 2rem 1rem;
  font-size: 0.75rem;
}
.chat-input-container {
  background-color: #f7f7f7;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.chat-input-container.hfn {
  animation-name: slideup;
  animation-delay: 0.1s;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.chat-input-container {
  .addon.btn {
    padding:3px 5px;
    bottom:0px;
  }
}

@keyframes slideup {
  0% {
    bottom: 0px;
    opacity: 0;
  }
  100% {
    bottom: calc(env(safe-area-inset-bottom) + 3.75rem);
    opacity: 1;
  }
}

.container-getting-started .chat-input-container.hfn {
  animation-name: slideup-gs;
  animation-delay: 0.1s;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

@keyframes slideup-gs {
  0% {
    bottom: 0px;
    opacity: 0;
  }
  100% {
    bottom: calc(env(safe-area-inset-bottom));
    opacity: 1;
  }
}



// media queries
@include media-breakpoint-up(md) {
  .chat-input-container {
    position: relative;
    bottom: auto;
    margin-top: 65px;
    textarea {
      height: 200px;
    }
  }
}


