.breadcrumb {
    margin-top:1rem;
    font-size: .875rem;
}
.breadcrumb-item + .breadcrumb-item::before{
    color: $dark;
    content: "> ";
    padding: 0px 5px 0 0;
}
.breadcrumb .breadcrumb-item:last-child::after{
    color: $dark;
    content: "> ";
    padding: 0px 0px 0 5px;
}