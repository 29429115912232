.forum-list {
  list-style: none;
  margin: 0;
  margin-right: -($grid-gutter-width/2);
  margin-left: -($grid-gutter-width/2);
  padding: 0;
  li {
    padding: 0;
    margin: 0;
    background-color: $white;

    > a,
    .flex-wrap {
      display: flex;
      flex-direction: row;
      align-items: top;
      align-content: stretch;
      flex-flow: row nowrap;
      justify-content: flex-start;
      border-bottom: 1px solid #ebebeb;
      padding: 15px;
      text-decoration: none;
    }
    > a:active {
      text-decoration: none;
    }

    .avatar {
      margin-right: 1rem;
      height: 40px;
      width: 40px;
    }
    .forum-list-details {
      .forum-list-title,
      .forum-list-username,
      .forum-list-info {
        display: block;
        color: $dark;
      }
      .forum-list-title {
        color: $primary;
        font-size: 1rem;
      }
      .forum-list-info {
        font-size: 0.625rem;
        margin-top: 0.5rem;
      }
    }
  }
}
