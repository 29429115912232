.navbar-footer {
  background-color: $white;
  color: $dark;
  //border-top: 1px solid #c0d2d6;
  padding: 0 0 1rem 0;
  box-shadow: 0rem -0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.1);
  margin: 0;
  .icon-wrap .solid{
    display:none;
  }
}
.navbar.fixed-bottom .navbar-nav > li > a > .icon-wrap {
  font-size: 1.5rem;
}
.navbar-footer .d-flex {
  width: 100%;
}
.navbar-footer .navbar-nav {
  width: 100%;
  padding: 0 0.5rem;
}
.navbar-footer .nav-item {
  width: 20%;
  display: inline-block;
}
.navbar-footer .nav-item .nav-link {
  //margin: 0 5px;
  margin: 0px;
  text-align: center;
  color: $dark;
  //border-top: 2px solid transparent;
  position: relative;
  padding: 0.188rem 0rem 0.5rem 0rem;
  .icon-wrap {
    img {
      margin-bottom: 0.25rem;
    }
  }
  .nav-label {
    font-size: 0.625rem;
    display: block;
    font-weight: normal;
    margin-top: -0.25rem;
    text-align: center;
    //left: 50%;
    //transform: translate(-50%, 0%);
    //position: absolute;
  }
}
.navbar-footer .nav-item.active .nav-link {
  color: $primary;
 // border-top: 2px solid $primary;
  .icon-wrap {
    .solid{
    display:block; 
    }
    .light{
    display:none;
    }
}
  .nav-label{
    color:$dark;
    font-weight: bold;
  }
}


// media queries
@include media-breakpoint-up(lg) {
.navbar-footer{
  display:none;
}
}


// media queries
@include media-breakpoint-up(lg) {
  .has-footer-nav{
    padding-bottom:0px;
  }
  

  .navbar-footer {
    display: none;
    
  }

}

@include media-breakpoint-down(lg) {
.navbar-footer{
  padding-bottom: calc(env(safe-area-inset-bottom) - 0.5rem);
}
.has-footer-nav {
  padding-bottom: calc(env(safe-area-inset-bottom) + 4.625rem);
}
}


// iPhone bottom menu extra depth

/*  iPhone 14 Pro Max, iPhone 15 Plus and iPhone 15 Pro Max 1284×2778 pixels at 460ppi 
@media only screen 
    and (width: 430px) 
    and (height: 932px) 
    and (orientation: portrait) 
    and (-webkit-device-pixel-ratio: 3) { 
      .navbar-footer {
        padding: 0 0 2rem 0;
      }
      .has-footer-nav .content-wrap {
        padding-bottom: 91px;
      }

    }
  */

/* iPhone 14 Pro & iPhone 15 and iPhone 15 Pro - 1179×2556 pixels at 460ppi 
@media only screen 
    and (width: 393px) 
    and (height: 852px) 
    and (orientation: portrait) 
    and (-webkit-device-pixel-ratio: 3) { 
      .navbar-footer {
        padding: 0 0 2rem 0;
      }
      .has-footer-nav .content-wrap {
        padding-bottom: 91px;
      }

    }
*/


/* iPhone 12 Pro Max, iPhone 13 Pro Max, and iPhone 14 Plus - 2778x1284 pixels at 458ppi 
@media only screen 
  and (device-width: 428px) 
  and (device-height: 926px) 
  and (orientation: portrait) 
  and (-webkit-device-pixel-ratio: 3) {
  .navbar-footer {
    padding: 0 0 2rem 0;
  }
  .has-footer-nav .content-wrap {
    padding-bottom: 91px;
  }
}
*/

/* iPhone 12, iPhone 12 Pro, iPhone 13, iPhone 13 Pro, and iPhone 14 - 2532x1170 pixels at 460ppi 
@media only screen 
  and (device-width: 390px) 
  and (device-height: 844px) 
  and (orientation: portrait) 
  and (-webkit-device-pixel-ratio: 3) {
  .navbar-footer {
    padding: 0 0 2rem 0;
  }
  .has-footer-nav .content-wrap {
    padding-bottom: 91px;
  }
}
*/

/* iPhone 12 Mini - 2340x1080 pixels at 476ppi 
@media only screen 
  and (device-width: 375px) 
  and (device-height: 812px) 
  and (orientation: portrait) 
  and (-webkit-device-pixel-ratio: 3) {
  .navbar-footer {
    padding: 0 0 2rem 0;
  }
  .has-footer-nav .content-wrap {
    padding-bottom: 91px;
  }
}
*/

/* iPhone 11 Pro Max AND iPhone Xs Max - 2688x1242px at 458ppi 
@media only screen 
  and (device-width: 414px) 
  and (device-height: 896px) 
  and (orientation: portrait) 
  and (-webkit-device-pixel-ratio: 3) {
  .navbar-footer {
    padding: 0 0 2rem 0;
  }
  .has-footer-nav .content-wrap {
    padding-bottom: 91px;
  }
}
*/

/* iPhone X, iPhone Xs, iPhone 11 Pro, iPhone 12 Mini, and iPhone 13 Mini - 2436x1125px at 458ppi 
@media only screen 
  and (device-width: 375px) 
  and (device-height: 812px) 
  and (orientation: portrait) 
  and (-webkit-device-pixel-ratio: 3) {
  .navbar-footer {
    padding: 0 0 2rem 0;
  }
  .has-footer-nav .content-wrap {
    padding-bottom: 91px;
  }
}
*/

/* iPhone 11 AND iPhone Xr - 1792x828px at 326ppi 
@media only screen 
  and (device-width: 414px) 
  and (device-height: 896px) 
  and (orientation: portrait) 
  and (-webkit-device-pixel-ratio: 2) {
  .navbar-footer {
    padding: 0 0 2rem 0;
  }
  .has-footer-nav .content-wrap {
    padding-bottom: 91px;
  }
}
*/

/* iPad Air 2020 - 2360x1640px at 264ppi 
@media only screen 
  and (device-width: 820px) 
  and (device-height: 1180px) 
  and (orientation: portrait) 
  and (-webkit-device-pixel-ratio: 2) {
  .navbar-footer {
    padding: 0 0 2rem 0;
  }
  .has-footer-nav .content-wrap {
    padding-bottom: 91px;
  }
}
*/
