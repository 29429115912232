.form-control{
  font-size:1rem;
}
.goal-form, .standard-form {
  label {
    color: $black;
  }
  fieldset legend {
    color: $secondary;
    font-size: 1rem;
    text-align: center;
  }
  .form-group {
    border-radius: $border-radius;
    // padding: 0.75rem;
    margin-top:0.75rem;
    margin-bottom:0.75rem;
    hr {
      border-top-color: $white;
    }
    .num {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      background-color: $dark;
      color: $white;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      left: 0.75rem;
    }
    .instructions {
      font-size: 0.75rem;
    }
  }
}
.goal-form-nums {
  .form-group label{
    padding-left:2.25rem;
    position: relative;
    .num {
      top:2px;
    }
  }

}

.num-alone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: $dark;
  color: $white;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  left: 0.75rem;
}

.required-info {
  margin: 0rem 0rem 1rem 0rem;
  font-size: 0.75rem;
}
.required {
  color: $danger;
}

.from-control-micro{
  display: inline-block;
  width:60px;
  font-size:1.5rem;
  line-height: 1rem;
  text-align: right;
}
.micro-addon{
  font-size:1.5rem;
  margin-left:1rem;
}

.from-control-xl-micro{
  display: inline-block;
  width:120px;
  font-size:2.25rem;
}
.xl-micro-addon{
  font-size:1.5rem;
  margin-left:.5rem;
}
// specials
.checkbox-container {
  border-top:1px solid $white;
  label {
    position: relative;
    padding: 1rem 0 1rem 3rem;
    margin-bottom: 0;
    .icon-wrap {
      position: absolute;
      margin-left: -2.5rem;
      font-size:1.5rem;
      line-height: 1.5rem;
    }
  }
  .checkbox {
    border-bottom: 1px solid $white;
    transition: 0.3s;
    &:hover {
      background-color: $white;
    }
  }
  input[type="checkbox"] {
    opacity: 0;
    width: 0px;
    position: absolute;
    
    + label {
      display: block;
    }
    &:checked {
      + label {
        // color: $white;
        // background: $primary;
        
      }
    }
    &:focus {
      + label {
        box-shadow: 0 0px 0px rgb(0,0,0,0);
        background-color: $dark;
        color: $white;
      }
    }
  }
}

.form-desc {
  font-size: 0.625rem;
  color:$secondary;
  line-height: 0.875rem;
}



h2.form-subheading{
  font-size:1.25rem;
  margin: 1rem 0;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  .form-subheading-aside{
    font-size:0.875rem;
    text-transform: none;
    font-weight: 400;
    color:#3C5A64;
    
  }
  .strength-icon{
    display: block;
    margin: 0.5rem 0;
    svg {
      height: 4.75rem;
      width: 4.75rem;
    }
  }
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.container-register{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 1rem;
  min-height: 100svh;

  a {
    text-decoration: none;
  }

  .btn-back {
    margin-left: -1rem;
    &:hover {
      color:$primary;
    }
  }
  
  

  .login-form {
    padding: 2rem 2rem 1rem 2rem;
  }
  
  h4.login-title {
    font-size: 1.5rem;
    font-weight: 400;
    margin: 1rem 0 1rem 0;
    text-align: center;
  }

  

  h2{
    font-size:1.25rem;
  }
  h3{
    font-size:0.875rem;
  }
}

.footer-logos {
  padding: 0rem 2rem 2rem 2rem;
  max-width: 500px;
  margin: 0 auto;
}

.programme-logos {
  justify-content: space-evenly;
}

.signin-details-flex {
  display: flex;
  justify-content:space-between;
}

@media (max-width: 410px) {
.container-getting-started {
  .signin-details-flex {
    display: block;
  }
}
}

.signin-details {
  font-size: 0.625rem;
  line-height: 1.125rem;
  color: #9194A1;
  margin: 1rem auto 0 auto;
  border-top: 1px solid rgba(0,0,0,0.05);
  border-bottom: 1px solid rgba(0,0,0,0.05);
  padding-top: 1rem;
  padding-bottom: 1rem;
  a {
    color: #9194A1;
    text-decoration: none;
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

.container-getting-started {
  .signin-details {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}


@include media-breakpoint-up(sm) {
    .signin-details {
      .signin-left {
        text-align: left;
      }
      .signin-right {
        text-align: right;
      }
    }
  }

@include media-breakpoint-down(sm) {
    .signin-details {
      .signin-left {
        text-align: center;
      }
      .signin-right {
        text-align: center;
      }
    }
  }

.terms-box{
  overflow: scroll;
  height: 300px;
  background-color: $light;
  border-radius: $border-radius;
  padding:1rem;
}

.ii-form{
  .form-group{
    background-color:$white;
    padding:1rem;
    border-radius: $border-radius;
    margin-bottom:1rem;
    label{
      display: block;
      margin:0 0 .5rem 0;
      padding:0;
      color:$dark;
      text-transform: uppercase;
      font-size:0.625rem;
      line-height:0.625rem;
      font-family: $headings-font-family;
      font-weight:bold;
    }
    .form-control{
      background-color: #ECF1F5;
      border:none;
      font-size:0.875rem;
    }
    .input-group-text{
      border:none;
      color:$secondary;
      background-color: #ECF1F5;
      text-transform: uppercase;
      font-size: 0.625rem;
      padding: 0 0.5rem 0 0;
      font-weight: 700;
    }

    .input-group-icon{
      border:none;
      color:$secondary;
      background-color: #ECF1F5;
      text-transform: uppercase;
      font-size: 0.875rem;
      padding: 0.25rem 0 0 0.5rem;
      font-weight: 700;
      border-radius: 0.5rem;
    }

    .number-field {
      width: 4rem;
    }
    .form-control.number-field {
      flex: none;
    }
  }
}