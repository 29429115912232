
.cc-window {
    background-color: $secondary!important;
    font-size: 0.875rem!important;
    box-shadow:  0px 2px 5px 0px rgba(0,0,0,0.25);
    color: $white!important;
    font-family: $font-family-sans-serif!important;
  }
  
  .cc-btn {
    border-radius: $border-radius!important;
    font-weight: 700!important;
    text-decoration: none!important;
    .cc-right {
      right: 50%!important;
      margin-right: -12rem!important;
    }
  
  }
  .cc-revoke{
    display:none !important;
    
  }

  .cc-allow {
    color: $dark!important;
    background-color:#7AE571!important;
    &:hover {
      background-color: #4DBF43!important;
    }
  }

  .cc-deny {
    color: $white!important;
    background-color:transparent!important;
    border: 2px solid rgba(255,255,255,0.2)!important;
    &:hover {
      background-color: rgba(255,255,255,0.1)!important;
      border: 2px solid rgba(255,255,255,0.2)!important;
    }
  }
