.page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-flow: row nowrap !important;
  justify-content: center;
  padding:0;
  margin: 1rem 0 0 0;
  h2 {
    font-size: 1rem;
    margin: 0;
    padding: 0;
    font-weight: 700;
    display: inline-block;
  }
  .subtitle{
      font-size:0.625rem;
      color:#3C5A64;
  }
  > div:nth-child(2) {
    flex: 1;
    text-align: center;
  }
  > div:first-child,
  > div:last-child {
    min-width: 40px;
    font-size:1.5rem;
    color:#3C5A64;
  }
  > div:last-child {
    text-align: right;
  }
}

.sub-page .page-header {
  margin: 0;
  h2 {
    padding: 0.875rem 0;
  }
}

.sub-page tabset .page-header {
  padding: 0;
  margin: 1rem 0 0.5rem 0;
  h2 {
    padding: 0;
  }
}

.btn-left {
  padding:0.625rem 1.5rem 0.625rem 0rem;
  text-align: left;
}

.btn-right {
  padding:0.625rem 0rem 0.625rem 1.5rem;
  text-align: right;
}

