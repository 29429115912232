.home-bg {
  background-image: url("/assets/img/bg-home.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: 50% 35%;
  min-height: 100svh;
  min-width: 100vw;
}
// media queries
@include media-breakpoint-up(lg) {
.home-bg {
  background-image: url("/assets/img/bg-home-desktop.jpg");
}
}

.home-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  min-height: 100svh;
  color: $white;
  align-items: center;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.85) 10%,
    rgba(0, 0, 0, 0.65) 20%,
    rgba(0, 0, 0, 0) 35%,
    rgba(0, 0, 0, 0) 65%,
    rgba(0, 0, 0, 0.65) 80%,
    rgba(0, 0, 0, 0.85) 90%,
    rgba(0, 0, 0, 0.9) 100%
  );
  .home-masthead {
    max-width: 500px;
    width: 100%;
    padding: 2rem;
    //background: linear-gradient(357.07deg, rgba(0, 0, 0, 0) 12.43%, rgba(0, 0, 0, 0.75) 32.4%, rgba(0, 0, 0, 0.85) 97.57%);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img {
      margin: 1rem 0 1rem 0;
    }

    h2 {
      font-size: 1.25rem;
      font-weight: normal;
      text-align: center;
    }
  }

  .home-footer {
    max-width: 500px;
    padding: 2rem;
    //background: linear-gradient(177.07deg, rgba(0, 0, 0, 0) 12.43%, rgba(0, 0, 0, 0.75) 32.4%, rgba(0, 0, 0, 0.85) 97.57%);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .home-buttons {
      width: 100%;
      button {
        margin-bottom: 0.5rem;
      }
      .btn-white {
        background-color: rgba(255,255,255,0.85);
        &:hover {
          background-color: $white;
        }
      }
      .btn-outline-white {
        &:hover {
          background-color: rgba(255,255,255,0.2);
          color:$white!important;
        }
      }
    }
    .footer-strap {
      font-size: 0.875rem;
      font-weight: normal;
      margin: 1rem 0 0.5rem 0;
    }
    .programme-logos {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      img{
        max-width: 80px !important;
      }
    }
  }
}
