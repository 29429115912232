.achievement-notification {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999;
  -webkit-animation: reveal-award 0.4s cubic-bezier(0.47, 0, 0.745, 0.715) both;
  animation: reveal-award 0.4s cubic-bezier(0.47, 0, 0.745, 0.715) both;
  background-color: rgba(0, 0, 0, 0.5);

  .back-link {
    color: $white;
    background-color: $dark;
    border-radius: 50%;
    width: 1.875rem;
    height: 1.875rem;
    min-width: 1.875rem;
    min-height: 1.875rem;
    display: inline-block;
    line-height: 1.875rem;
    text-align: center;
    font-size: 1rem;
    margin-right: 0.6rem;
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
  }
  .achievement-wrap {
    width:300px;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
    text-align: center;
    h1 {
      font-size: 1.125rem;
    }
    
    .achievement {
        
    }
  }
}

.bg-light {
  .achievement-wrap {
    color: $dark;
  }
}
