.todo-progress{
    background-color: $light;
    padding:1rem;
    text-align: center;
    .progress{
        height:14px;
        margin-bottom:.75rem;
        .icon-wrap{
            font-size:0.5rem;
        }
    }
    .progress-strap{
        font-size:0.875rem;
    }
}
