.quiz {
  .quiz-result {
    font-size: 1.25rem;
  }

  .quiz-question {
    background-color: #fff;
    border-radius: $border-radius;
    margin-bottom: 1rem;
    padding: 1rem;

    h4 {
      font-size: 1.125rem;
      margin-bottom: 1rem;
    }
    
    p {
      margin-bottom: 0rem;
    }

    .pick-list li.correct {
      background-color: $light;
      color: $dark;
      .icon-wrap {
        color: $success;
      }
  }
  .pick-list li.incorrect {
      background-color: $light;
      color: $dark;
      .icon-wrap {
        color: $danger;
      }
  }

    .pick-list li.correct.picked {
      font-weight: bold;
      .your-answer {
        color: $secondary;
        font-size: 0.75rem;
        font-weight: 400;
      }
    }
    .pick-list li.incorrect.picked {
        font-weight: bold;
        .your-answer {
          color: $secondary;
          font-size: 0.75rem;
          font-weight: 400;
        }
    }
  }
}
@media (min-width: 781px) {
  .border-radius-bottom-only {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
}

@media (max-width: 780px) {
  .border-radius-bottom-only {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
