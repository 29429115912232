.progress{
    border-radius:0.25rem;
    //border:1px solid #ccc;
    height:0.625rem;
    background-color: transparent;
    .progress-bar:first-child{
    border-radius: 5px 0 0 5px;
    //border-left:1px solid $white;
    }
    .progress-bar:last-child{
        border-radius:  0 0.25rem 0.25rem 0;
        }
}

.progress-icons-ends{
    padding:0 2rem;
    position: relative;
    .icon-left{
        position: absolute;
        left:0;
        top:0;
        font-size:1.125rem;
    }
    .icon-right{
        position: absolute;
        right:0;
        top:0;
        font-size:1.125rem;
    }
}
.progress-dividers{
    .progress-bar{
        //border-top:1px solid $white;
        //border-bottom:1px solid $white;
        //border-right:1px solid $white;
        margin-right: 0.125rem;
    }
}

.progress-bar.bg-white{
    color:$black;
}
.progress-symptom{
    position: relative;
    background-color: #E4E7EB;
    border:none;
    height:6px;
    max-width: 22rem;
    margin: 0 auto;
    .line:nth-child(2){
        left:20%;
    }
    .line:nth-child(3){
        left:40%;
    }
    .line:nth-child(4){
        left:60%;
    }
    .line:nth-child(5){
        left:80%;
    }
    .line{
        position: absolute;
        width:1px;
        height:100%;
        top:0;
        background-color:$white;
    }
}




.progress-activity{
    height:auto;
    .progress-bar{
        color:$dark;
        padding: 4px 0;
        .icon-wrap{
            font-size:1.125rem;
            line-height:1rem;
        }
    }
    .bg-success{
        color:$white;
    }
}

.activity-details {
    border-top: 1px solid $white;
    padding: 10px 0 0 0;
    font-size: 1.125rem;
}

