
/*
.loading-spinner-wrapper{
  background-color: rgba(255, 255, 255, .9);
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .loading-spinner-wrapper {
    background-color: rgba(255, 255, 255, .5);
    -webkit-backdrop-filter: blur(.5em);
    backdrop-filter: blur(.5em);
  }
}
*/

.loading-spinner-sm{
  height:20px !important;

.lds-ring {
  transform: scale(.5);
}

.lds-msg{display:none;}
}
.loading-spinner-wrapper{
  position: fixed;
  width:100dvw;
  height:100dvh;
  left:0;
  z-index: 5;
  background-color: rgba(255, 255, 255, .5);
}
.loading-spinner-inline{
  position: relative;
  height:64px;
  margin:10px;
  .lsd-ring{
    position: relative;
  }
}
.lds-msg{
  display: inline-block;
  position: absolute;
  width: 300px;
  height: 64px;
  top:40%;
  left:50%;
  margin-left:-150px;
  margin-top:64px;
  text-align: left;
  color:$dark;
}
.lds-ring {
  display: inline-block;
  position: absolute;
  width: 64px;
  height: 64px;
  top:40%;
  left:50%;
  margin-top:-32px;
  margin-left:-32px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid $dark;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $dark transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}