.eq5d-form {
h4 {
  font-size: 1rem;
  font-weight: 700;
}

  .radio-group {
    border-top: 1px solid $white;
    font-size: 0.875rem;
    .radio {
      border-bottom: 1px solid $white;
    }
    input[type="radio"] {
      opacity: 0;
      width: 0px;

      + label {
        display: block;
        .icon-wrap {
          font-size: 1.5rem;
          line-height: 1.5rem;
        }
        .icon-wrap .selected {
            display:none;
        }
        .icon-wrap .unselected {
            display:inline-block;
        }
      }

      &:checked {
        + label {
          background-color: $secondary;
          color: $white;
            .icon-wrap .selected {
                display:inline-block;
              }
              .icon-wrap .unselected {
                display:none;
              }
          // color: $white;
          // background: $primary;
        }
      }
      &:focus {
        + label {
          background-color: $secondary;
          color: $white;
        }
      }
    }
  }

  .checkbox-container {
    input[type="checkbox"] {
      &:focus {
        + label {
          background-color: $secondary;
          color: $white;
          box-shadow: 0 0px 0px rgb(0,0,0,0);
        }
      }
    }
  }
  .form-check{
    padding-left:0;
    margin-bottom: 0rem;
  }
}


.health-box{
    background-color:$dark;
    color:$white;
    font-size:1rem;
    text-align: center;
    width:150px;
    padding:2rem;
    margin:2rem auto;
    .value{
        font-size:1.5rem;
    }
}
.healthrating-container{
    height:460px;
    margin:2rem auto;
    text-align:center;
}
.healthrating-label{
    margin:1rem;
}
.healthrating-scale{
    height:400px;
    text-align:left;
    margin-left:40%;

}


  .eq5d-slider .ngx-slider .ngx-slider-bar {
    background: $dark;
    height: 2px;
  }
  .eq5d-slider .ngx-slider .ngx-slider-selection {
    background:$dark;
  }

  .eq5d-slider .ngx-slider .ngx-slider-pointer {
    width: 16px;
    height: 16px;
    top: auto; /* to remove the default positioning */
    
    bottom: 0;
    background-color: $danger;
    border-radius: 50%;
  }
  
  .eq5d-slider .ngx-slider.vertical .ngx-slider-pointer{
    left: -6px!important;
  }

  .eq5d-slider .ngx-slider .ngx-slider-pointer:after {
    display: none;
  }

  .eq5d-slider .ngx-slider .ngx-slider-bubble {
    bottom: 14px;
  }

  .eq5d-slider .ngx-slider .ngx-slider-limit {
    font-weight: bold;
    color: $dark;
  }

  .eq5d-slider .ngx-slider .ngx-slider-tick {
    width: 1px;
    height: 10px;
    margin-left: 4px;
    border-radius: 0;
    background: $dark;
    top: -1px;
  }
  .eq5d-slider .ngx-slider.vertical .ngx-slider-tick {
    width: 20px;
    height: 1px;
    margin-left: 0px;
    border-radius: 0;
    background: $dark;
    top: -1px;
    left:-5px
  }
  .eq5d-slider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
    background:$dark;
  }
  .eq5d-slider .ngx-slider.vertical .ngx-slider-tick-legend {
    top: -5px;
  }


  .eq5d-slider{
    .ngx-slider-ceil, .ngx-slider-floor{
      opacity:0 !important;
    }
  }


  .internal-app {
    .eq5d-form {
    .tool-options {
      padding: 0rem;
      label {
    padding: 1rem 0.5rem 1rem 3rem;
    .icon-wrap{
      margin-left: -2.4rem;
    }
      }
      
    }
    .btn {
      padding: 0.5rem;
      font-size: 0.875rem;
    }
  }
  }


  
