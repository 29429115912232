//
.dashboard-masthead-container {
  position: relative;
  .dashboard-masthead-close {
    position: absolute;
    z-index: 10;
    top: 10px;
    right: 10px;
    border: 0;
    padding: 0;
    display:flex;
    width:18px;
    height:18px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $white;
    font-size:1rem;
  }
}
.dashboard-masthead {
  min-height: 15.625rem;
  height: 27.5svh;
  position: relative;
  display: flex;
  align-items: flex-stretch;
  .dashboard-masthead-content {
    color: $white;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-grow: 1;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0.65) 70%,
      rgba(0, 0, 0, 0.85) 90%,
      rgba(0, 0, 0, 0.9) 100%
    );

    .dashboard-masthead-content-inner {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        padding-bottom: 1rem;

        h2{
            font-size:1.25rem;
            margin:0 0 .125rem 0;
            padding:0
        }
        .dashboard-masthead-subtitle{
            font-size:0.75rem;
            margin:0 0 .5rem 0;
            font-weight: normal;
        }
        .entry-days{
            margin:0 0 .25rem 0;
            .entry-day{
                font-size:0.625rem;
               .entry-day-label{
                margin:0;
                font-weight: normal;
               }
            }
        }
        .dashboard-masthead-subtext{
            font-size:0.625rem;
            font-weight: normal;
            margin:0.25rem 0 0.25rem 0;

        }

        .progress{
          height:12px;
          margin:0 0 .25rem 0;
          .bg-field{
            background-color:$white;
            opacity: 0.25;
          }
        }
    }
  }
}
.dashboard-masthead-cardio {
  background-image: url(/assets/img/db-slider-cardio.jpg);
  background-size: cover;
  background-position: 50% 20%;
  background-repeat: no-repeat;
}
.dashboard-masthead-strength {
  background-image: url(/assets/img/db-slider-strength.jpg);
  background-size: cover;
  background-position: 50% 20%;
  background-repeat: no-repeat;
}
.dashboard-masthead-health {
  background-image: url(/assets/img/db-slider-health.jpg);
  background-size: cover;
  background-position: 50% 20%;
  background-repeat: no-repeat;
}
.dashboard-masthead-bitesize {
  background-image: url(/assets/img/db-placeholder-bitesize.jpg);
  background-size: cover;
  background-position: 50% 20%;
  background-repeat: no-repeat;
}
.dashboard-masthead-goals {
  background-image: url(/assets/img/db-slider-goals.jpg);
  background-size: cover;
  background-position: 50% 20%;
  background-repeat: no-repeat;
}

.dashboard-masthead-goals-icons{
  gap:10px;
  justify-content: center;
  align-items: center;
  font-size:1rem;
  font-family: $headings-font-family;
  >div{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-wrap{
    font-size:1.5rem;
    margin-right:5px;
  }
}

.swiper-pagination {
  bottom: 1.125rem!important;
  right: 1rem!important;
  width: revert!important;
  left: unset!important;
  .swiper-pagination-bullet {
    background: #fff;
    opacity: 0.25!important;
  }

  .swiper-pagination-bullet-active {
    background: #fff;
    opacity: 1!important;
  }
}

@include media-breakpoint-up(sm) {
  .dashboard-masthead {
    height: 35svh;
  }
  }

@include media-breakpoint-up(lg) {
  .dashboard-masthead-container {
    border-radius: 0 0 0.5rem 0.5rem;
    overflow: hidden
  }

}



@include media-breakpoint-up(xl) {
  .dashboard-masthead {
    height: 40svh;
  }
}