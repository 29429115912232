
// achievements
.achievements {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: row;
    clear: both;
    flex-wrap: wrap;
  }
  
  .achievements {
    .achievement {
      margin-bottom: 1rem;
    }
  }
  
  .achievement {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $white;
    border-radius: 0.625rem;
    width: 3.75rem;
    height: 3.75rem;
    position: relative;
    margin: 0 0.5rem;
  
    .achievement-bg {
      position: absolute;
      top: 0;
      left: 0;
      line-height: 3.75rem;
      width: 100%;
      height: 100%;
      z-index: 0;
      color: #eee;
      text-align: center;
      font-size: 3rem;
    }
  
    .achievement-content {
      text-align: center;
      position: relative;
      z-index: 1;
      font-size: 0.5rem;
      font-family: $headings-font-family;
      color: $primary;
    }
  
    .achievement-value {
      font-size: 1.125rem;
      font-weight: 800;
      line-height: 1.125rem;
    }
  
    .achievement-label {
      text-transform: uppercase;
      white-space: pre;
      line-height: 1;
      margin-top: 0.25rem;
  
      span {
        line-height: 1;
      }
    }
  
    .achievement-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  
    .achievement-icon {
      width: 1.25rem;
      display: inline-block;
  
      svg {
        width: 100%;
        height: auto;
        fill: $primary;
      }
  
      
    }
  }
  
  .achievement-primary {
    background-color: $primary;
    color: $white;
  
    .achievement-content {
      color: $white;
    }
  
    .achievement-icon {
      margin-right: 0.25rem;
  
      svg {
        fill: $white;
        fill: $white;
      }
    }
  }



  .achievement-container-sm {
    width: 75px;
    height: 75px;
    min-width: 75px;
    min-height: 75px;
    font-size: 16px;
  }

  .achievement-container-md {
    width: 150px;
    height: 150px;
    font-size: 32px;
  }

  .achievement-container-lg {
    width: 300px;
    height: 300px;
    font-size: 64px;
  }


.achievement-milestone {
  border: 0.125em solid rgba(0,0,0,0.2);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0.125em 0.125em 0.25em 0em rgba(0,0,0,0.25);
  -moz-box-shadow: 0.125em 0.125em 0.25em 0em rgba(0,0,0,0.25);
  box-shadow: 0.125em 0.125em 0.25em 0em rgba(0,0,0,0.25);
  font-family: $headings-font-family;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  text-shadow: 0.063em 0.063em 0em rgba(0, 0, 0, 0.1);
  color: $white;
  svg {
    filter: drop-shadow(0.04em 0.04em 0em rgba(0, 0, 0, 0.1)); 
  }
}

.achievement-streak {
  border: 0.125em solid rgba(0,0,0,0.2);
  border-radius: 0.5em;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0.125em 0.125em 0.25em 0em rgba(0,0,0,0.25);
  -moz-box-shadow: 0.125em 0.125em 0.25em 0em rgba(0,0,0,0.25);
  box-shadow: 0.125em 0.125em 0.25em 0em rgba(0,0,0,0.25);
  font-family: $headings-font-family;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  text-shadow: 0.063em 0.063em 0em rgba(0, 0, 0, 0.1);
  color: $white;
  svg {
    filter: drop-shadow(0.04em 0.04em 0em rgba(0, 0, 0, 0.1)); 
  }
}

.achievement-header {
  display: inline-flex;
  margin: 0 auto 0 auto;
  padding-top: 0.25em;
  margin-bottom: -0.25em;
}

.achievement-icon {
  flex: 1;
  font-size: 1.5em;
}

.achievement-icon-text {
  flex: 1;
}

.achievement-icon-main-text {
  font-size: 1.5em;
  line-height: 1.5em;
}

.achievement-icon-sub-text {
  font-size: 0.625em;
  line-height: 1em;
  margin-top: 0.25em;
  display: block;
}


.achievement-badge-sub-text {
  font-size: 0.625em;
  line-height: 1em;
}

.achievement-milestone-exercise {
  background: rgb(204,159,6);
  background: linear-gradient(45deg, rgba(204,159,6,1) 20%, rgba(204,159,6,0.75) 50%, rgba(204,159,6,1) 80%);
  background-origin: border-box;
  .achievement-header {
    padding-top: 0.125em;
    padding-bottom: 0.125em;
  }
  .achievement-badge-sub-text {
    margin-top: -0.25em;
  }
  img {
    height: 1em;
    filter: drop-shadow(0.04em 0.04em 0em rgba(0, 0, 0, 0.1));
    margin-top: -0.25em;
  }
}

.achievement-streak-exercise {
  background: rgb(135,42,134);
  background: linear-gradient(45deg, rgba(135,42,134,1) 20%, rgba(135,42,134,0.75) 50%, rgba(135,42,134,1) 80%);
  background-origin: border-box;
  .achievement-header {
    margin: 0 auto -0.25em auto;
    .achievement-icon {
      font-size: 2em;
      margin-top: -0.2em;
    }

    .achievement-icon-text {
      margin-top: -0.4em;
    }

    .achievement-icon-sub-text {
      margin-top: -.625em;
      margin-bottom: 1em;
    }

    img {
      height: 1em;
      filter: drop-shadow(0.04em 0.04em 0em rgba(0, 0, 0, 0.1));
      margin-top: -0.25em;
    }
  }
}

.achievement-milestone-goals {
  background: rgb(0,82,133);
  background: linear-gradient(45deg, rgba(0,82,133,1) 20%, rgba(0,82,133,0.75) 50%, rgba(0,82,133,1) 80%);
  background-origin: border-box;
  .achievement-header {
    padding-top: 0.75em;
    margin-bottom: -0.5em;
    .achievement-icon {
      margin-right: 0.1em;
    }
  }
}

.achievement-milestone-programme {
  background: rgb(0,182,106);
  background: linear-gradient(45deg, rgba(0,182,106,1) 20%, rgba(0,182,106,0.6) 50%, rgba(0,182,106,1) 80%);
  background-origin: border-box;
  .achievement-icon-main-text {
    margin-left: 0.1em;
  }
}

.achievement-streak-programme {
  background: rgb(229,1,126);
  background: linear-gradient(45deg, rgba(229,1,126,1) 20%, rgba(229,1,126,0.6) 50%, rgba(229,1,126,1) 80%);
  background-origin: border-box;

  .achievement-badge-sub-text {
    padding: 0.5em 0 0 0;
  }

  .achievement-icon-main-text {
    font-size: 2.25em;
    margin-top: -0.3em;
  }

  img {
    height: 0.5em;
    filter: drop-shadow(0.04em 0.04em 0em rgba(0, 0, 0, 0.1));
    margin-top: -1.65em;
  }
}


.achievement-milestone.achievement-milestone-exercise.faded, .achievement-streak.achievement-streak-exercise.faded, .achievement-milestone.achievement-milestone-goals.faded, .achievement-milestone.achievement-milestone-programme.faded, .achievement-streak.achievement-streak-programme.faded {
  text-shadow: none!important;
  background:$white!important;
  -webkit-box-shadow: 0em 0em 0em 0em rgba(0,0,0,0)!important;
  -moz-box-shadow: 0em 0em 0em 0em rgba(0,0,0,0)!important;
  box-shadow: 0em 0em 0em 0em rgba(0,0,0,0)!important;
  border: 0.125em solid $white!important;
  color: #D2D5D9!important;

  
  svg {
    filter: drop-shadow(0em 0em 0em rgba(0, 0, 0, 0))!important; 
  }

  img {
    filter: drop-shadow(0em 0em 0em rgba(0, 0, 0, 0)) brightness(0) saturate(100%) invert(98%) sepia(1%) saturate(890%) hue-rotate(180deg) brightness(90%) contrast(88%)!important;
  }
}

.achievements-card {
  .card-body {
    padding: 1rem 0 0 0;
  }

  h2 {
    padding: 0 0 0.5rem 0;
  }
}





  // end achievements