.theme-impact{
    .bs-datepicker-body table td.week span
    {
      color: $secondary !important;
    }
  
    .bs-datepicker-body table.days span{
      background-color:$light;
      color:$dark;
      font-weight:bold;
    }
    .bs-datepicker-body table td{
      padding:2px;
    }
    .bs-datepicker-body table td span.selected,
    .bs-datepicker-head,
    .bs-datepicker-head, .bs-datepicker button:active,
    .bs-datepicker-body table td.selected span,
    .bs-datepicker-body table td span[class*="select-"]:after,
    .bs-datepicker-body table td[class*="select-"] span:after,
    .bs-datepicker-body table td.active-week span:hover
    {
      color:$white;
      background-color: $primary !important;
    }
    .bs-datepicker-body table td span.is-other-month, .bs-datepicker-body table td.is-other-month span{
  
      color: rgba($primary, 0.7) !important;
    }
    .bs-datepicker-body table.days span.disabled{
      background-color:#eee;
      color:#999;
      font-weight:normal;
    }
  }
  
  .datepicker-today{
    text-decoration: underline;
  }