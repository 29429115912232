.forum-posts{
    list-style: none;
    margin:0;
    margin-right:-($grid-gutter-width/2);
    margin-left:-($grid-gutter-width/2);
    padding:0;
    li{
            background-color:$white;
            padding:15px;
            margin-bottom:1rem;
            .flex-wrap{
                display: flex;
                flex-direction: row;
                align-items: top;
                align-content: stretch;
                flex-flow: row nowrap !important;
                justify-content: flex-start;
                .avatar{
                    margin-right:1rem;
                    height:40px;
                    width:40px;
                }
                .post-list-user{
                    color:$dark;
                    .post-list-username{
                        font-size:.875rem;
                        display:block;
                    }
                    .post-list-userstatus{
                        font-size:.75rem;
                        display:block;
                    }

                }
            }
            .post-list-info{
                margin:15px -15px;
                background-color:$dark;
                color:$white;
                padding:.4rem 1rem;
                font-size:.75rem;
                display: block;
                position: relative;
                .post-list-info-num{
                    float:right;
                }
                
            }
            .post-list-info::after {
                content: '';
               position: absolute;
               width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 8px 8px 8px;
                border-color: transparent transparent $dark transparent;
                top:-8px;
                left:27px;
           
           }
            .post-list-post{
                color:$dark;
                font-size:.75rem;
            }

        }
    }

.search-result{
    margin:0 15px;
    .topic-title{
        font-size:1rem;
        padding:1rem 0;
    }
    border:1px solid $light;
    padding:1rem;
    .post-list-info{
        margin:15px 0;
    }
}